import React, { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Table, Modal, Form, Input, message, Select, Upload, Button, Image } from 'antd';
import SubNavbar from '../Header/SubNavbar';
import { FaEdit, FaTrash, FaListUl } from 'react-icons/fa';
import { UploadOutlined } from '@ant-design/icons';
import CustomButton from '../Button/CustomButton';
import { BASE_URL } from '../Constant/config';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PreLoader from '../PreLoader/PreLoader';
import { AiOutlineAppstore } from 'react-icons/ai';
dayjs.extend(customParseFormat);

let allstatusText = [];
allstatusText['recovery'] = 'Recovery';
allstatusText['active'] = 'Active';
allstatusText['pending_for_allocation'] = 'Pending For Allocation';
allstatusText['partner_inventory'] = 'Partner Inventory';
allstatusText['sold_out'] = 'Sold Out';
allstatusText['eliminated'] = 'Eliminated';
allstatusText['service'] = 'Service';

let allstatusColors = [];
allstatusColors['recovery'] = '#8A0102';
allstatusColors['active'] = '#248B22';
allstatusColors['pending_for_allocation'] = '#02469B';
allstatusColors['service'] = '#02469B';
allstatusColors['partner_inventory'] = '#FF4500';
allstatusColors['sold_out'] = '#A9A9A9';
allstatusColors['eliminated'] = '#A9A9A9';

const PartnersDataTable = () => {
  const contentToPrint = useRef(null);
  const [modalType, setModalType] = useState('');
  const [partnerForm] = Form.useForm();
  const [isLoader, setIsLoader] = useState(true);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAllocationModalVisible, setIsAllocationModalVisible] = useState(false);
  const [isDisAllocationModalVisible, setIsDisAllocationModalVisible] = useState(false);
  const [isQrCodeModalVisible, setIsQrCodeModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowPFABBKeys, setSelectedRowPFABBKeys] = useState([]);
  const [selectedRowDisPFABBKeys, setSelectedRowDisPFABBKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [leadersData, setLeadersData] = useState([]);
  const [locationsData, setLocationsData] = useState([]);
  const [pendingForAllocationBBoxes, setPendingForAllocationBBoxes] = useState([]);  
  const [forDisAllocationBBoxes, setForDisAllocationBBoxes] = useState([]);  
  const [perPageItem, setPerPageItem] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPFABBItems, setTotalPFABBItems] = useState(0);
  const [totalDisPFABBItems, setTotalDisPFABBItems] = useState(0);
  const [batteryBoxDetails, setBatteryBoxDetails] = useState('');
  const [currentUserDetails, setCurrentUserDetails] = useState({});
  const [currentLeader, setCurrentLeader] = useState([]);
  const { Option } = Select;

  const [filters, setFilters] = useState({
    Partners: '',
    Company: '',
    POC: '',
    Mobile: '',
    Associate: '',
    Batteries: '',
    Address: ''
  });

  useEffect(() => {
    fetchData(currentPage, perPageItem);
    fetchLocationsData();
  }, []);

  const fetchData = async (page, pageSize) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/admin/get-users?role=partner&limit=${pageSize}&page=${page}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        const data = result.data.map((item, index) => ({
          key: item.user,
          _id: item._id,
          rowData: item,
          Partners: item.uniqueCode,
          Company: item.companyName,
          POC: item.pocName,
          Mobile: item.mobile.toString(),
          Associate: item.teamLead ? item.teamLead.name ? item.teamLead.name : '' : '',
          Address: item.address ? item.address : '',
          Batteries: item.batteries
        }));
        setDataSource(data);
        setTotalItems(result.totalCount);
        setIsLoader(false);
      } else {
        console.error('Failed to fetch data:', result.message);
        setIsLoader(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoader(false);
    }
  };

  const fetchLocationsData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/location/get-locations?limit=50000&page=1`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && Array.isArray(result.data.locations)) {
        const locationsList = result.data.locations.map(location => ({
          _id: location._id,
          title: location.locality ? location.locality:"-"+"|"+location.city ? location.city:"-"+"|"+location.state ? location.state:"-"+"|"+location.pincode ? location.pincode:"-",
        }));
        setLocationsData(locationsList);
      } else {
        console.error('Unexpected data format:', result);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const getAllLeadersByLocation = async (locationId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/admin/get-leaders-based-on-location/${locationId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && Array.isArray(result.data)) {
        setLeadersData(result.data);
        console.log(result.data);
      } else {
        console.error('Unexpected data format:', result);
      }
    } catch (error) {
      console.error('Error fetching leaders:', error);
    }
  };

  const handleEdit = async (event, record) => { 
    event.preventDefault();
    setIsLoader(true);
    try {
      const token = localStorage.getItem('token');
      const userId = record._id;
      const response = await fetch(`${BASE_URL}/partner/get-partner/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        setCurrentUserDetails(result.data);
        if(result.data.partner.teamLead){
          setCurrentLeader([result.data.partner.teamLead]);
        }
        showModal('edit', record._id);
        setIsLoader(false);
      } else {
        console.error('Failed to fetch data:', result.message);
        setIsLoader(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoader(false);
    }
    getAllLeadersByLocation(record.rowData.locationR._id);
    partnerForm.setFieldsValue({ 
      companyName: record.rowData.companyName,
      pocName: record.rowData.pocName,
      mobile: record.rowData.mobile,
      email: record.rowData.email,
      latitude: record.rowData.location.coordinates[1],
      longitude: record.rowData.location.coordinates[0],
      address: record.rowData.address,
      city: record.rowData.city,
      state: record.rowData.state,
      pincode: record.rowData.pincode,
      locationR: record.rowData.locationR ? record.rowData.locationR._id ? record.rowData.locationR._id : '' : '',
      teamLead: record.rowData.teamLead ? record.rowData.teamLead.id ? record.rowData.teamLead.id : '' : '',
      gstNumber: record.rowData.gstNumber,
      companyPAN: record.rowData.companyPAN,
      bankName: record.rowData.bankName,
      accountNumber: record.rowData.accountNumber,
      ifscCode: record.rowData.ifscCode,
      gstURL: '',
      panURL: '',
      cancelledChequeURL: '',
      profilePictureURL: ''
    });
  };

  const handleDelete = async (event, record) => {
    event.preventDefault();
    let deleteUsers = { users: [] };
    if(record?._id){
      deleteUsers.users = [record.rowData.user];
    } else {      
      if(selectedRowKeys.length !== 0){
        deleteUsers.users = selectedRowKeys;
      }
    }    
    if(deleteUsers.users.length > 0) {
      Swal.fire({
        title: "Are you sure? ",
        html: "You want to delete <br/>You won't be able to revert this!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
            confirmButton: "btn btn-success mx-1",
            cancelButton: "btn btn-danger mx-1"
        },
        buttonsStyling: !1,
      }).then(async function (t) {
          if(t.isConfirmed === true){
            const token = localStorage.getItem('token');
            const response = await fetch(`${BASE_URL}/admin/delete-user/`, {
              method: 'PUT',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(deleteUsers),
            });
            const result = await response.json();
            if(result.statusCode === 200){
              message.success(result.message);
              fetchData(currentPage, perPageItem);
            } else {
              message.error('Error');
            }
          }
      });
    }
  };

  const handlePrint = useReactToPrint({
    bodyClass:"centered-content",
    documentTitle: "Battery Box QR Code",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  const handlePendingAllocation = async (event, record) => {
    event.preventDefault(); 
    console.log(record); 
    setCurrentUserDetails(record);
    try {
      const token = localStorage.getItem('token');
      const userId = record._id;
      const response = await fetch(`${BASE_URL}/battery-box/get-battery-boxes?status=pending_for_allocation&limit=2000&page=1`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        setPendingForAllocationBBoxes(result.data);
        setIsAllocationModalVisible(true);
        setTotalPFABBItems(result.data.length);
        setSelectedRowPFABBKeys([]);
      } else {
        console.error('Failed to fetch data:', result.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleDisAllocation = async (event, record) => {
    event.preventDefault(); 
    setCurrentUserDetails(record);
    if(record.Batteries.total > 0){
      try {
        const token = localStorage.getItem('token');
        const userId = record._id;
        const response = await fetch(`${BASE_URL}/battery-box/get-battery-boxes-allocated-to-partner/${userId}?limit=2000&page=1`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        if (result.statusCode === 200) {
          setForDisAllocationBBoxes(result.data);
          setIsDisAllocationModalVisible(true);
          //setTotalDisPFABBItems(result.data.length);
          setSelectedRowDisPFABBKeys([]);
        } else {
          console.error('Failed to fetch data:', result.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }

  const handleOpenQrCode = async (event, record) => {
    event.preventDefault(); 
    try {
      setIsLoader(true);
      const token = localStorage.getItem('token');
      const battryBoxId = record.batteryBox._id;
      const response = await fetch(`${BASE_URL}/battery-box/get-battery-box/${battryBoxId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200 || result.statusCode === 201) {
        setBatteryBoxDetails(result.data);
        setIsQrCodeModalVisible(true);
        setIsLoader(false);
      } else {
        message.error('Failed to get battery box details - ' + result.message);
        setIsLoader(false);
      }
    } catch (error) {
      message.error('Error to get battery box details');
      setIsLoader(false);
    }
  }

  const handleAddClick = () => {
    setCurrentUserDetails({});
    setCurrentLeader([]);
    partnerForm.resetFields();
    showModal('add');
  };

  const showModal = (type, record = null) => {
    setModalType(type);
    setCurrentRecord(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (modalType === 'add') {
      const validationResult = partnerForm.validateFields(['companyName', 'pocName', 'mobile', 'latitude', 'longitude', 'address', 'city', 'state', 'pincode', 'locationR', 'gstNumber', 'gstURL', 'companyPAN', 'panURL', 'bankName', 'accountNumber', 'ifscCode', 'cancelledChequeURL', 'profilePictureURL']).then(async (values) => {
        try {
          setIsLoader(true);
          const token = localStorage.getItem('token');
          const createFormData = partnerForm.getFieldsValue(true);      
          const FormData = require('form-data');
          const formData = new FormData();
          for (const key in createFormData) {
            formData.append(key, createFormData[key]);
          }        
          createFormData['panURL'].fileList ? formData.append('panURL', createFormData['panURL'].fileList[0].originFileObj) : formData.delete("panURL");
          createFormData['gstURL'].fileList ? formData.append('gstURL', createFormData['gstURL'].fileList[0].originFileObj) : formData.delete("gstURL");
          createFormData['cancelledChequeURL'].fileList ? formData.append('cancelledChequeURL', createFormData['cancelledChequeURL'].fileList[0].originFileObj) : formData.delete("cancelledChequeURL");
          createFormData['profilePictureURL'].fileList ? formData.append('profilePictureURL', createFormData['profilePictureURL'].fileList[0].originFileObj) : formData.delete("profilePictureURL");
          const response = await fetch(`${BASE_URL}/admin/create-partner`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: formData,
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success('Partner created successfully');
            fetchData(currentPage, perPageItem);
            setIsModalVisible(false);
            partnerForm.resetFields();
            setIsLoader(false);
          } else {
            message.error('Failed to create partner - ' + result.message);
            setIsLoader(false);
          }
        } catch (error) {
          message.error('Error creating partner');
          setIsLoader(false);
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
    } else {
      const validationResult = partnerForm.validateFields(['companyName', 'pocName', 'mobile', 'latitude', 'longitude', 'address', 'city', 'state', 'pincode', 'locationR', 'gstNumber', 'companyPAN', 'bankName', 'accountNumber', 'ifscCode']).then(async (values) => {
        try {
          setIsLoader(true);
          const token = localStorage.getItem('token');
          const createFormData = partnerForm.getFieldsValue(true);       
          const FormData = require('form-data');
          const formData = new FormData();
          for (const key in createFormData) {
            formData.append(key, createFormData[key]);
          }
          createFormData['panURL'] ? createFormData['panURL'].fileList ? formData.append('panURL', createFormData['panURL'].fileList[0].originFileObj) : formData.delete("panURL") : formData.delete("panURL");
          createFormData['gstURL'] ? createFormData['gstURL'].fileList ? formData.append('gstURL', createFormData['gstURL'].fileList[0].originFileObj) : formData.delete("gstURL") : formData.delete("gstURL");
          createFormData['cancelledChequeURL'] ? createFormData['cancelledChequeURL'].fileList ? formData.append('cancelledChequeURL', createFormData['cancelledChequeURL'].fileList[0].originFileObj) : formData.delete("cancelledChequeURL") : formData.delete("cancelledChequeURL");
          createFormData['profilePictureURL'] ? createFormData['profilePictureURL'].fileList ? formData.append('profilePictureURL', createFormData['profilePictureURL'].fileList[0].originFileObj) : formData.delete("profilePictureURL") : formData.delete("profilePictureURL");
          const partnerId = currentRecord; 
          const response = await fetch(`${BASE_URL}/partner/update-partner/${partnerId}`, {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: formData,
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success('Partner updated successfully');
            fetchData(currentPage, perPageItem);
            setIsModalVisible(false);
            partnerForm.resetFields();
            setIsLoader(false);
          } else {
            message.error('Failed to update partner - ' + result.message);
            setIsLoader(false);
          }
        } catch (error) {
          message.error('Error updateing partner');
          setIsLoader(false);
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsAllocationModalVisible(false);
    setIsDisAllocationModalVisible(false);
    setIsQrCodeModalVisible(false);
  };

  const handleExportClick = async () => {
    setIsLoader(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/admin/export-users?role=partner`,{
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      const result = await response.json();
      if(result.statusCode === 200){
        //handleDownload(result.csvLink);
        let newObjectWithLabels = [];
        newObjectWithLabels.push(result.csvLabels);
        result.csvData.forEach(element => {
            newObjectWithLabels.push(element);
        });
        const csvData = new Blob([convertToCSV(newObjectWithLabels)], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `partners.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setIsLoader(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoader(false);
    }
  }

  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  const handleDownload = (csvLink) => {
    if (csvLink) {
      const link = document.createElement('a');
      link.href = csvLink;
      link.setAttribute('download', 'partners.csv'); // Set the desired filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const Buttons = [
    { text: 'Export', onClick: handleExportClick },
    { text: 'Add', onClick: handleAddClick },
    { text: 'Delete', onClick: handleDelete },
  ];

  const filteredData = dataSource.filter(item =>
    (filters.Partners === '' || item.Partners.toLowerCase().includes(filters.Partners.toLowerCase())) &&
    (filters.Company === '' || item.Company.toLowerCase().includes(filters.Company.toLowerCase())) &&
    (filters.POC === '' || item.POC.toLowerCase().includes(filters.POC.toLowerCase())) &&
    (filters.Mobile === '' || item.Mobile.toLowerCase().includes(filters.Mobile.toLowerCase())) &&
    (filters.Associate === '' || item.Associate.toLowerCase().includes(filters.Associate.toLowerCase())) &&
    (filters.Batteries === '' || item.Batteries.includes(filters.Batteries)) &&
    (filters.Address === '' || item.Address.toLowerCase().includes(filters.Address.toLowerCase()))
  );

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setCurrentPage(pagination.current);
    setPerPageItem(pagination.pageSize);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onSelectPFABBChange = (newSelectedRowKeys) => {
    setSelectedRowPFABBKeys(newSelectedRowKeys);
  };

  const onSelectDisPFABBChange = (newSelectedRowKeys) => {
    setSelectedRowDisPFABBKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const rowSelectionOfPFABB = {
    selectedRowPFABBKeys,
    onChange: onSelectPFABBChange,
  }

  const rowSelectionOfDisPFABB = {
    selectedRowDisPFABBKeys,
    onChange: onSelectDisPFABBChange,
  }
  
  const handleFilterChange = (dataIndex, value) => {
    setFilters((prev) => ({
      ...prev,
      [dataIndex]: value,
    }));
  };

  const columns = [
    {
      title: (
        <div>
          <div>Partners Code</div>
          <Input
            placeholder="Partners Code"
            value={filters.Partners}
            onChange={(e) => handleFilterChange('Partners', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Partners',
      fixed: 'left',
      sorter: (a, b) => a.Partners.localeCompare(b.Partners),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Company Name</div>
          <Input
            placeholder="Company Name"
            value={filters.Company}
            onChange={(e) => handleFilterChange('Company', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Company',
      sorter: (a, b) => a.Company.localeCompare(b.Company),
      width: 200,
    },
    {
      title: (
        <div>
          <div>POC Name</div>
          <Input
            placeholder="POC Name"
            value={filters.POC}
            onChange={(e) => handleFilterChange('POC', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'POC',
      sorter: (a, b) => a.POC.localeCompare(b.POC),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Mobile</div>
          <Input
            placeholder="Mobile"
            value={filters.Mobile}
            onChange={(e) => handleFilterChange('Mobile', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Mobile',
      sorter: (a, b) => a.Mobile.localeCompare(b.Mobile),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Associate TL</div>
          <Input
            placeholder="Associate TL"
            value={filters.Associate}
            onChange={(e) => handleFilterChange('Associate', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Associate',
      sorter: (a, b) => a.Associate.localeCompare(b.Associate),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Batteries</div>
          <p>&nbsp;</p>
          {/* <Input
            placeholder="Batteries"
            value={filters.Batteries}
            onChange={(e) => handleFilterChange('Batteries', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'Batteries',
      width: 200,
      render: (_, record) => (
        <>
          <span onClick={(event) => handleDisAllocation(event, record)} style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px', 'cursor': 'pointer'}}>{record.Batteries.total}</span>
          <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>=</span>
          <span style={{'background':allstatusColors['active'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.active}</span>
          {record.Batteries.recovery > 0 ? (
            <>
              <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>+</span>
              <span style={{'background':allstatusColors['recovery'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.recovery}</span>
            </>
          ) : ''}
          {record.Batteries.service > 0 ? (
            <>
              <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>+</span>
              <span style={{'background':allstatusColors['service'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.service}</span>
            </>
          ) : ''}
          {record.Batteries.pending_for_allocation > 0 ? (
            <>
              <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>+</span>
              <span style={{'background':allstatusColors['pending_for_allocation'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.pending_for_allocation}</span>
            </>
          ) : ''}
          {record.Batteries.partner_inventory > 0 ? (
            <>
              <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>+</span>
              <span style={{'background':allstatusColors['partner_inventory'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.partner_inventory}</span>
            </>
          ) : ''}
          {record.Batteries.sold_out > 0 ? (
            <>
              <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>+</span>
              <span style={{'background':allstatusColors['sold_out'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.sold_out}</span>
            </>
          ) : ''}
          {record.Batteries.eliminated > 0 ? (
            <>
              <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>+</span>
              <span style={{'background':allstatusColors['eliminated'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.eliminated}</span>
            </>
          ) : ''}
        </>
      )
    },
    {
      title: (
        <div>
          <div>Address</div>
          <Input
            placeholder="Address"
            value={filters.Address}
            onChange={(e) => handleFilterChange('Address', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Address',
      sorter: (a, b) => a.Address.localeCompare(b.Address),
      width: 200,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: 'right',
      width: 120,
      render: (_, record) => (
        <span>
          <a href='#' onClick={(event) => handlePendingAllocation(event, record)}>
            <FaListUl style={{ cursor: 'pointer', marginRight: 16 }} />
          </a>
          <a href='#'>
            <FaEdit onClick={(event) => handleEdit(event, record)} style={{ cursor: 'pointer', marginRight: 16 }} />
          </a>
          <a href='#'>
            <FaTrash onClick={(event) => handleDelete(event, record)} style={{ cursor: 'pointer' }} />
          </a>
        </span>
      ),
    },
  ];

  const pendingForAllocColumns = [       
    {
      title: 'Battery Box No.',
      dataIndex: 'batteryBoxSerialNumber',
      width: '150px',
      sorter: (a, b) => a.batteryBoxSerialNumber.localeCompare(b.batteryBoxSerialNumber),

    }, 
    {
      title: 'Manufactured Date',
      dataIndex: 'manufacturedDate',
      width: '200px',
      sorter: (a, b) => a.manufacturedDate.localeCompare(b.manufacturedDate),
      render: (_, record) => (
        <>
          {record.battery.manufacturedDate ? (
            dayjs(record.battery.manufacturedDate).format('DD/MM/YYYY')
          ) : (
            '-'
          )}
        </>
      ),
    }, 
    {
      title: 'Specifications',
      dataIndex: 'specifications',
      width: '200px',
      sorter: (a, b) => a.specifications.localeCompare(b.specifications),
      render: (_, record) => (
        <>
          {record.battery.specifications ? (
            record.battery.specifications
          ) : (
            '-'
          )}
        </>
      ),
    },
  ];

  const pendingForDisAllocColumns = [      
    {
      title: 'Battery Box No.',
      dataIndex: 'batteryBoxSerialNumber',
      width: '150px',
      render: (_, record) => (
        <>
          {record.batteryBox.batteryBoxSerialNumber ? (
            record.batteryBox.batteryBoxSerialNumber
          ) : (
            '-'
          )}
        </>
      ),
    },      
    {
      title: 'Partner Allotment Date',
      dataIndex: 'partnerAllotmentDate',
      width: '150px',
      render: (_, record) => (
        <>
          {record.partnerAllotmentDate ? (
            dayjs(record.partnerAllotmentDate).format('DD/MM/YYYY')
          ) : (
            '-'
          )}
        </>
      ),
    },      
    {
      title: 'Driver',
      dataIndex: 'batteryBoxSerialNumber',
      width: '150px',
      render: (_, record) => (
        <>
          {record.driver ? (
            record.driver
          ) : (
            '-'
          )}
        </>
      ),
    },      
    {
      title: 'Driver Allotment Date',
      dataIndex: 'batteryBoxSerialNumber',
      width: '150px',
      render: (_, record) => (
        <>
          {record.driverAllotmentDate ? (
            dayjs(record.driverAllotmentDate).format('DD/MM/YYYY')
          ) : (
            '-'
          )}
        </>
      ),
    },      
    {
      title: 'Status',
      dataIndex: 'status',
      width: '150px',   
      render(text, record) {
        return {
          props: {
            style: { 
              'background': record.batteryBox.status ? allstatusColors[record.batteryBox.status] : "",
              'color' : "#fff",
              'font-weight' :  "500"
            }
          },
          children: record.batteryBox.status ? allstatusText[record.batteryBox.status] : '-'
        };
      }
    },      
    {
      title: 'Action',
      dataIndex: 'action',
      width: '80px',
      render: (_, record) => (
        <span>
          <a href="#" onClick={(event) => handleOpenQrCode(event, record)}>
            <AiOutlineAppstore style={{ cursor: 'pointer', marginRight: 10 }} />
          </a>
        </span>
      ),

    }, 
  ]

  const onChangePFABB = async () => {
    console.log('onChangePFABB');
  }
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    // setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleChangeLocation = (e) =>{
    partnerForm.setFieldsValue({ 
      teamLead : ''
    });
    if(e === ''){
      setLeadersData([]);
    } else {
      getAllLeadersByLocation(e);
    }
  }

  const handleAllocateBattriesToPartner = async () => {
    console.log(selectedRowPFABBKeys);
    if(selectedRowPFABBKeys.length > 0){
      try{
        const token = localStorage.getItem('token');
        let battriesDataToUpdate = {
          "partner": currentUserDetails._id,
          "batteryBoxId": selectedRowPFABBKeys
        };
        console.log(battriesDataToUpdate);
        const response = await fetch(`${BASE_URL}/battery-box/allocate-to-partner`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(battriesDataToUpdate),
        });
        const result = await response.json();
        if(result.statusCode === 200 || result.statusCode === 201){
          message.success(result.message);
          fetchData(currentPage, perPageItem);
          setIsAllocationModalVisible(false);
        } else {
          message.error('Error');
        }
      } catch (error) {
        message.error('Error allocation of battries to partner - ', error);
      }
    }
  }

  const handleExportAllocateBattriesOfPartner = async () => {
    setIsLoader(true);
    try {
      const partnerId = currentUserDetails._id;
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/battery-box/export-get-battery-boxes-allocated-to-partner/${partnerId}`,{
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      const result = await response.json();
      if(result.statusCode === 200){
        //handleDownload(result.csvLink);
        let newObjectWithLabels = [];
        newObjectWithLabels.push(result.csvLabels);
        result.csvData.forEach(element => {
            newObjectWithLabels.push(element);
        });
        const csvData = new Blob([convertToCSV(newObjectWithLabels)], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `partner-battery-boxes.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setIsLoader(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoader(false);
    }
  }

  const handleDisAllocateBattriesToPartner = async () => {
    console.log(selectedRowDisPFABBKeys);
    if(selectedRowDisPFABBKeys.length > 0){
      try{
        const token = localStorage.getItem('token');
        let battriesDataToUpdate = {
          "partnerId": currentUserDetails._id,
          "batteryBoxId": selectedRowDisPFABBKeys
        };
        const response = await fetch(`${BASE_URL}/battery-box/disallocate-from-partner/`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(battriesDataToUpdate),
        });
        const result = await response.json();
        if(result.statusCode === 200 || result.statusCode === 201){
          message.success(result.message);
          fetchData(currentPage, perPageItem);
          setIsDisAllocationModalVisible(false);
        } else {
          message.error('Error');
        }
      } catch (error) {
        message.error('Error disallocation of battries to partner - ', error);
      }
    }
  }

  const uploadProps = {
    beforeUpload: (file) => {
      return false;
    },
    accept: 'image/*',
    maxCount: 1
  };

  return (
    <>
      {isLoader ? (
        <PreLoader/>
      ):''}
      <SubNavbar pageName="Manage Partners" buttons={Buttons} />
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive mt-2">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              rowKey={(record) => record._id}
              dataSource={filteredData}
              onChange={onChange}
              scroll={{y: `calc(100vh - 280px)`}}
              pagination={{
                simple: false,
                size:"small",
                total: totalItems,
                showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                showQuickJumper: false,
                showLessItems: true,
                responsive:true,
                defaultPageSize:perPageItem,
                pageSizeOptions:[5,10,20,50,100],
                position: ['topRight'],
                onChange: (page, pageSize) => {
                  console.log(page, pageSize);
                  fetchData(page, pageSize);
                }
              }}
              bordered
            />
          </div>
        </div>
      </div>
      <Modal
        title={modalType === 'edit' ? 'Edit Partners' : 'Add Partners'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[
          <CustomButton key="submit" onClick={handleOk} text={modalType === 'edit' ? 'Save changes' : 'Add'} />
        ]}
        width={750}
      >
        <Form form={partnerForm}>
            <div className="row my-2">
              <div className="col-6">
                <h6>General Details</h6>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="Company" className="form-label">Company Name:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="companyName" label="" rules={[{ required: true, message: 'Please enter company name!' }]}>
                      <Input
                        placeholder="Company Name"
                        className="form-control"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="pocName" className="form-label">POC Name:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="pocName" label="" rules={[{ required: true, message: 'Please enter poc name!' }]}>
                      <Input
                        placeholder="POC Name"
                        className="form-control"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="Mobile" className="form-label">Mobile:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="mobile" label="" rules={[{ required: true, message: 'Please enter mobile!' }]}>
                      <Input
                        placeholder="Mobile"
                        className="form-control"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="Address" className="form-label">Address:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="address" label="" rules={[{ required: true, message: 'Please enter address!' }]}>
                      <Input
                        placeholder="Address"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="latitude" className="form-label">Latitude:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="latitude" label="" rules={[{ required: true, message: 'Please enter latitude!' }]}>
                      <Input
                        placeholder="Latitude"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="longitude" className="form-label">Longitude:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="longitude" label="" rules={[{ required: true, message: 'Please enter longitude!' }]}>
                      <Input
                        placeholder="Longitude"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="city" className="form-label">City:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="city" label="" rules={[{ required: true, message: 'Please enter city!' }]}>
                      <Input
                        placeholder="City"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="State" className="form-label">State:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="state" label="" rules={[{ required: true, message: 'Please enter state!' }]}>
                      <Input
                        placeholder="State"
                        className="form-control"
                      />
                    </Form.Item>    
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="pincode" className="form-label">Pin Code:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="pincode" label="" rules={[{ required: true, message: 'Please enter pincode!' }]}>
                      <Input
                        placeholder="Pin Code"
                        className="form-control"
                      />
                    </Form.Item>  
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="locationR" className="form-label">Location</label>
                  </div>
                  <div className="col-8">    
                    <Form.Item name="locationR" label="" rules={[{ required: true, message: 'Please select location!' }]}>          
                      <Select
                          showSearch
                          placeholder="Select a Location"
                          optionFilterProp="label"  // Searches within the value prop
                          style={{ width: '100%' }}
                          onChange={handleChangeLocation}
                          filterOption={(input, option) =>
                            option?.children.toLowerCase().includes(input.toLowerCase())
                          }
                      >
                        <Option value="">--Select Location--</Option>
                        {locationsData.map(location => (
                            <Option key={location._id} value={location._id}>{location.title}</Option>
                        ))}            
                      </Select>
                    </Form.Item>
                  </div>
              </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="TL" className="form-label">Associate TL</label>
                  </div>
                  <div className="col-8">    
                    <Form.Item name="teamLead" label="">             
                      <Select
                          showSearch
                          placeholder="Select a TL"
                          optionFilterProp="label"  // Searches within the value prop
                          style={{ width: '100%' }}
                          filterOption={(input, option) =>
                            option?.children.toLowerCase().includes(input.toLowerCase())
                          }
                      >   
                        <Option value="">--Select TL--</Option>
                        {leadersData.map(leader => (
                            <Option key={leader._id} value={leader._id}>{leader.name}</Option>
                        ))}                    
                        {currentLeader.map(leader => (
                          (leadersData.find(item => item._id === leader._id) === false ? <Option key={leader._id} value={leader._id}>{leader.name}</Option> : '')
                        ))}         
                      </Select>
                    </Form.Item>
                  </div>
              </div>
              </div>
              <div className="col-6">
                <div>
                  <h6>Company Details</h6>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="gstNumber" className="form-label">GST Number:</label>
                    </div>
                    <div className="col-8">
                      <Form.Item name="gstNumber" label="" rules={[{ required: true, message: 'Please enter gst no.!' }]}>
                        <Input
                          placeholder="GST Number"
                          className="form-control"
                        />
                      </Form.Item> 
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="gstURL" className="form-label">GST Certificate:</label>
                    </div>
                    <div className="col-8 images-block-upload">
                      <Form.Item name="gstURL" label="" rules={[{ required: true, message: 'Please upload gst image!' }]}>
                        <Upload {...uploadProps}>
                          <Button icon={<UploadOutlined />}>Select File</Button>
                        </Upload>
                      </Form.Item> 
                      {currentUserDetails.partner && currentUserDetails.partner.gstURL ?                  
                      <Image
                        width={30}
                        height={30}
                        src={currentUserDetails.partner.gstURL}
                      /> : ''}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="companyPAN" className="form-label">Company PAN:</label>
                    </div>
                    <div className="col-8">
                      <Form.Item name="companyPAN" label="" rules={[{ required: true, message: 'Please enter company pan!' }]}>
                        <Input
                          placeholder="Company PAN"
                          className="form-control"
                        />
                      </Form.Item> 
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="panURL" className="form-label">PAN:</label>
                    </div>
                    <div className="col-8 images-block-upload">
                      <Form.Item name="panURL" label="" rules={[{ required: true, message: 'Please upload pan image!' }]}>
                        <Upload {...uploadProps}>
                          <Button icon={<UploadOutlined />}>Select File</Button>
                        </Upload>
                      </Form.Item>
                      {currentUserDetails.partner && currentUserDetails.partner.panURL ?                  
                      <Image
                        width={30}
                        height={30}
                        src={currentUserDetails.partner.panURL}
                      /> : ''}
                    </div>
                  </div>
                </div>                
                <div>
                  <h6>Bank Details</h6>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="bankName" className="form-label">Bank Name:</label>
                    </div>
                    <div className="col-8">
                      <Form.Item name="bankName" label="" rules={[{ required: true, message: 'Please enter bank name!' }]}>
                        <Input
                          placeholder="Bank Name"
                          className="form-control"
                        />
                      </Form.Item> 
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="accountNumber" className="form-label">Account Number:</label>
                    </div>
                    <div className="col-8">
                      <Form.Item name="accountNumber" label="" rules={[{ required: true, message: 'Please enter account no.!' }]}>
                        <Input
                          placeholder="Account Number"
                          className="form-control"
                        />
                      </Form.Item> 
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="ifscCode" className="form-label">IFSC Code:</label>
                    </div>
                    <div className="col-8">
                      <Form.Item name="ifscCode" label="" rules={[{ required: true, message: 'Please enter ifsc code!' }]}>
                        <Input
                          placeholder="IFSC Code"
                          className="form-control"
                        />
                      </Form.Item> 
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="cancelledChequeURL" className="form-label">Cancelled Cheque:</label>
                    </div>
                    <div className="col-8 images-block-upload">
                      <Form.Item name="cancelledChequeURL" label="" rules={[{ required: true, message: 'Please upload cancelled cheque image!' }]}>
                        <Upload {...uploadProps}>
                          <Button icon={<UploadOutlined />}>Select File</Button>
                        </Upload>
                      </Form.Item>
                      {currentUserDetails.partner && currentUserDetails.partner.cancelledChequeURL ?                  
                      <Image
                        width={30}
                        height={30}
                        src={currentUserDetails.partner.cancelledChequeURL}
                      /> : ''}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="profilePictureURL" className="form-label">Profile Picture:</label>
                    </div>
                    <div className="col-8 images-block-upload">
                      <Form.Item name="profilePictureURL" label="" rules={[{ required: true, message: 'Please upload profile picture image!' }]}>
                        <Upload {...uploadProps}>
                          <Button icon={<UploadOutlined />}>Select File</Button>
                        </Upload>
                      </Form.Item>
                      {currentUserDetails.partner && currentUserDetails.partner.profilePictureURL ?                  
                      <Image
                        width={30}
                        height={30}
                        src={currentUserDetails.partner.profilePictureURL}
                      /> : ''}
                    </div>
                  </div>
                </div>

              </div>
            </div>
        </Form>
      </Modal>      
      <Modal
        title="Allocate Batteries"
        visible={isAllocationModalVisible}
        onCancel={handleCancel}
        centered
        footer={[
          <CustomButton key="submit" onClick={handleAllocateBattriesToPartner} text="Allocate"/>
        ]}
        width={'90%'}
      >
        <div className="col-sm-12">
          <div className="table-responsive mt-2">
            <Table
              rowKey={(record) => record._id}
              rowSelection={rowSelectionOfPFABB}
              columns={pendingForAllocColumns}
              dataSource={pendingForAllocationBBoxes}
              scroll={{y: `300px`}}
              pagination={{
                simple: false,
                size:"small",
                total: totalPFABBItems,
                showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                showQuickJumper: false,
                showLessItems: true,
                responsive:true,
                defaultPageSize:5,
                pageSizeOptions:[5,10,20,50,100],
                position: ['topRight']
              }}
              bordered
            />
          </div>
        </div>
      </Modal>     
      <Modal
        title="Battery Details"
        visible={isDisAllocationModalVisible}
        onCancel={handleCancel}
        centered
        footer={[
          <CustomButton key="export" onClick={handleExportAllocateBattriesOfPartner} text="Export"/>,
          <CustomButton key="button" onClick={handleDisAllocateBattriesToPartner} text="Disallocate"/>
        ]}
        width={'90%'}
      >
        <div className="col-sm-12">
          <div className="table-responsive mt-2">
            <Table
              rowKey={(record) => record.batteryBox._id}
              rowSelection={rowSelectionOfDisPFABB}
              columns={pendingForDisAllocColumns}
              dataSource={forDisAllocationBBoxes}
              scroll={{y: `300px`}}
              pagination={{
                simple: false,
                size:"small",
                total: totalDisPFABBItems,
                showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                showQuickJumper: false,
                showLessItems: true,
                responsive:true,
                defaultPageSize:5,
                pageSizeOptions:[5,10,20,50,100],
                position: ['topRight']
              }}
              bordered
            />
          </div>
        </div>
      </Modal>
      <Modal
        title=''
        visible={isQrCodeModalVisible}
        onCancel={handleCancel}
        footer={null}
        centered
        width={200}
      >
        <div className='row'>
          <div className='col-md-12 text-center'>
            <div className='col-12' ref={contentToPrint}>
              <h6 className='mt-2'>{batteryBoxDetails.batteryBoxSerialNumber}</h6>
              <img src={batteryBoxDetails.qrURL} className='img-fluid'/>
            </div>
            <CustomButton key="download" onClick={() => { handlePrint(null, () => contentToPrint.current); }}  text='Print'/>
          </div>
        </div>
      </Modal>  
    </>
  );
};

export default PartnersDataTable;
