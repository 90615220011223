import React, { useState, useEffect } from 'react';
import { FaMapMarkerAlt, FaBars, FaUserAlt, FaRegChartBar, FaCommentAlt, FaTools, FaShoppingBag, FaCogs, FaMoneyCheckAlt, FaBatteryFull, FaUserFriends, FaUserTie, FaCaretDown, FaCaretUp, FaAngleRight, FaTicketAlt, FaFile } from "react-icons/fa";
import { LuLogOut } from "react-icons/lu";
import Logo from '../../assests/power-hub-logo.jpg';
import './Sidebar.css';
import colors from '../../components/Constant/Color';
import { NavLink, useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import dayjs from 'dayjs';

const Sidebar = ({ children }) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isOpen, setIsOpen] = useState(window.innerWidth >= 280);

    const toggle = () => setIsOpen(!isOpen);

    const [activeSubmenu, setActiveSubmenu] = useState(null);
    const loginUser = JSON.parse(localStorage.getItem('loginUserData'));

    const checkTokenExpiryAndlogout = (userToken) => {
        const jwtTokenData = jwtDecode(userToken);
        const nowTimeDat = dayjs();
        if (jwtTokenData?.exp < nowTimeDat.unix()) {
            navigate('/user/logout');
        }
    }

    const checkIsUserLoggedIn = () => {
      const userToken = localStorage.getItem('token');
      if(userToken !== null){
        setIsLoggedIn(true);
        checkTokenExpiryAndlogout(userToken);
      } else {
        setIsLoggedIn(false);
      }
    }
  
    const handleSubmenuToggle = (event, index) => {
        setIsOpen(true);
        setActiveSubmenu(activeSubmenu == index ? null : index);
    };

    useEffect(() => {
        checkIsUserLoggedIn();
        
        const interval = setInterval(() => {
            checkIsUserLoggedIn();
        }, 1000);

        const handleResize = () => {
            if (window.innerWidth >= 280) {
                setIsOpen(true);
            } else {
                setIsOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleLogout = () => {
        navigate("/Login");
        console.log("Logging out...");
    };

    const menuItem = [
        { path: "/dashboard", name: "Dashboard", icon: <FaMapMarkerAlt /> },
        { 
            path: "/users", 
            name: "Tools", 
            icon: <FaTools />,
            onClick: "showChild",
            children: [
                { path: "/locations", name: "Location", icon: <FaMapMarkerAlt /> },
                { path: "/inventories", name: "Inventory", icon: <FaUserAlt /> },
                { path: "/manufacturers", name: "Manufacturer", icon: <FaRegChartBar /> },
                { path: "/partners", name: "Partners", icon: <FaCommentAlt /> },
                { path: "/team-leads", name: "Team Leads", icon: <FaUserTie /> },
                { path: "/drivers", name: "Drivers", icon: <FaShoppingBag /> },
                { path: "/recover-batteries", name: "Recovery", icon: <FaBatteryFull /> },
                { path: "/immobilize", name: "Immobilize", icon: <FaMoneyCheckAlt /> },
            ]
        },        
        { 
            path: "/manage-collections", 
            name: "Finance", 
            icon: <FaMoneyCheckAlt />,
            onClick: "showChild",
            children: [
                { path: "/manage-collections", name: "Collections", icon: <FaMoneyCheckAlt /> },
                { path: "/manage-payments", name: "Payments", icon: <FaMoneyCheckAlt /> },
            ]
        },               
        { 
            path: "/settings", 
            name: "Settings", 
            icon: <FaCogs/>,
            onClick: "showChild",
            children: [
                { path: "/settings/global-settings", name: "Global", icon: <FaCogs /> },
                { path: "/settings/issues", name: "Issues", icon: <FaMoneyCheckAlt /> },
                { path: "/settings/offers", name: "Offers", icon: <FaMoneyCheckAlt /> },
                { path: "/settings/user-roles", name: "User Roles", icon: <FaCogs /> },
                // { path: "/settings/notifications", name: "Notifications", icon: <FaMoneyCheckAlt /> },
                { path: "/settings/promotional-content", name: "Promotional Content", icon: <FaMoneyCheckAlt /> },
            ]
        },  
        { path: "/help-tickets", name: "Help Tickets", icon: <FaTicketAlt /> },
        { path: "/health-report", name: "Health Report", icon: <FaFile /> },             
        { 
            path: "/profile", 
            name: loginUser ? loginUser.name : '', 
            icon: <FaUserAlt/>,
            onClick: "showChild",
            children: [
                { path: "/my-profile", name: "My Profile", icon: <FaCogs /> },
                { path: "/user/logout", name: "Logout", icon: <LuLogOut /> },
            ]
        },  
    ];

    return (
        isLoggedIn ? (
            <div className="p-0">
                <div className={`sidebar ${isOpen ? 'open' : 'collapsed'}`} style={{
                    width: isOpen ? "240px" : "50px",
                    background: colors.primary,
                    height: '100vh',
                    position: 'fixed',
                    overflow: 'hidden',
                    zIndex: 1000,
                    transition: 'width 0.3s ease',
                    top: 0,
                    left: 0,
                    display: window.innerWidth < 280 && !isOpen ? 'none' : 'block',
                }}>
                    <div className="top_section">
                        <img src={Logo} style={{ display: isOpen ? 'block' : 'none', width: "180px" }} className="logo" alt="Logo" />
                        <div style={{ marginLeft: isOpen ? "50px" : "0px" }} className="bars">
                            <FaBars onClick={toggle} />
                        </div>
                    </div>                
                    <ul className="nav" style={{
                        width: '100%',
                        height: 'calc(100vh - 70px)',
                        overflow: 'auto',
                        position: 'relative',
                        display: 'block'
                    }}>
                        {
                            menuItem.map((item, index) => (
                                item.children ? (
                                    <li className='nav-item'>
                                        <div key={index} className="link" onClick={(event) => handleSubmenuToggle(event, index)} style={{ cursor: 'pointer' }}>
                                            <div className="icon">{item.icon}</div>
                                            <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                                            <span className="caret">
                                                {activeSubmenu == index ? (
                                                    <FaCaretUp/>
                                                ) : (
                                                    <FaCaretDown/>
                                                )}                                            
                                            </span>
                                        </div>
                                        <ul className={activeSubmenu == index ? ('sub-nav nav') : ('sub-nav nav d-none')}>
                                            {item.children.map((subitem, subindex) => (
                                                <li className='nav-item'>
                                                    <NavLink to={subitem.path} className="link" activeClassName="active">
                                                        <div className="sub-nav-icon"><FaAngleRight/></div>
                                                        <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{subitem.name}</div>
                                                    </NavLink>  
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ) : (
                                    <li className='nav-item' key={index} onClick={(event) => handleSubmenuToggle(event, index)}>
                                        <NavLink to={item.path} className="link" activeClassName="active">
                                            <div className="icon">{item.icon}</div>
                                            <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                                        </NavLink>                    
                                    </li>
                                )
                            ))
                        }
                    </ul>
                </div>
                <main style={{
                    marginLeft: isOpen ? "240px" : "50px",
                    transition: 'margin-left 0.3s ease',
                    zIndex: 1,
                    position: 'relative'
                }}>
                    {children}
                </main>
                {window.innerWidth < 280 && isOpen && (
                    <div className="overlay" onClick={toggle}></div>
                )}
            </div>
        ) : children
    );
};

export default Sidebar;
