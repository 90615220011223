import React, { useState, useEffect } from 'react';
import SubNavbar from '../components/Header/SubNavbar';
import ColumnChart from '../components/charts/ColumnChart';
import LineChart from '../components/charts/LineChart';
import BarChart from '../components/charts/BarChart';
import PieChart from '../components/charts/PieChart';
import {FaCaretDown, FaCaretUp } from "react-icons/fa";
import OnImg from '../assests/on.jpg';
import OffImg from '../assests/off.jpg';
import NoNetworkImg from '../assests/no-network.jpg';
import IdealImg from '../assests/ideal.jpg';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { BASE_URL } from '../components/Constant/config';

function Dashboard() {
  const gMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const [mapMarkers, setMapMarkers] = useState([]);
  const [batteryStatus, setBatteryStatus] = useState({});
  const [overAllSummery, setOverAllSummery] = useState({});
  const [last12MonthCollection, setLast12MonthCollection] = useState([]);
  const [leadsVsLeaseTrend, setLeadsVsLeaseTrend] = useState([]);
  const [locationWiseTrend, setLocationWiseTrend] = useState([]);
  const [batteryInventory, setBatteryInventory] = useState([]);
  const [leadsSummary, setLeadsSummary] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [helpTicketsData, setHelpTicketsData] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };
  const AllMarkesrs = [
      {
          "batteryBoxSerialNumber": "PHBB191243",
          "location": {
              "latitude": "28.6675205",
              "longitude": "77.2326179"
          }
      },
      {
          "batteryBoxSerialNumber": "PHBB851907",
          "location": {
              "latitude": "29.999741",
              "longitude": "79.23909"
          }
      },
      {
          "batteryBoxSerialNumber": "PHBB340662",
          "location": {
              "latitude": "30.1770202",
              "longitude": "78.1272163"
          }
      },
      {
          "batteryBoxSerialNumber": "PHBB452713",
          "location": {
              "latitude": "30.2854517",
              "longitude": "77.9900467"
          }
      },
      {
          "batteryBoxSerialNumber": "PHBB818156",
          "location": {
              "latitude": "30.3157313",
              "longitude": "78.0334613"
          }
      }
  ];

  const fetchOverAllCollection = async () => {
      try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${BASE_URL}/dashboard/overall-summary`,{
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
          });
          const result = await response.json();
      if (result.statusCode === 200) {
        setOverAllSummery(result.data);
      } else {
          console.error('Failed to fetch data:', result.message);
      }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  const fetchLast12MonthCollection = async () => {
      try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${BASE_URL}/dashboard/last-twelve-month-collection`,{
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
          });
          const result = await response.json();
      if (result.statusCode === 200) {
        let expectedData = [];
        let recivedData = [];
        Object.entries(result.data).map(([pos, detail]) => (
          expectedData.push({ label: pos, y: detail.expected }),
          recivedData.push({ label: pos, y: detail.collected })
        ));
        setLast12MonthCollection([{
            type: "column",
            color: "#7CB5EC",
            name: "Expected Amount",
            legendText: "Expected Amount",
            showInLegend: true, 
            dataPoints:expectedData
        },
        {
            type: "column",	
            color: "#434348",
            name: "Recived Amount",
            legendText: "Recived Amount",
            axisYType: "secondary",
            showInLegend: true,
            dataPoints:recivedData
        }]);
      } else {
          console.error('Failed to fetch data:', result.message);
      }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  const fetchLeadsVsLeaseTrend = async () => {
      try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${BASE_URL}/dashboard/get-leads-vs-lease-trend`,{
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
          });
          const result = await response.json();
      if (result.statusCode === 200) {
        let leadsData = [];
        let leaseData = [];
        Object.entries(result.data).map(([pos, detail]) => (
          leadsData.push({ label: pos, y: detail.leads }),
          leaseData.push({ label: pos, y: detail.leases })
        ));
        setLeadsVsLeaseTrend([{
          type: "spline", 
          showInLegend: true,
          color: "#3BAFCD",
          yValueFormatString: "#,##,###",
          name: "Sales",
          dataPoints:leadsData
        },
        {
          type: "spline", 
          showInLegend: true,
          color: "#9A8A43",
          yValueFormatString: "#,##,###",
          name: "Site Visits",
          dataPoints:leaseData
        }]);
      } else {
          console.error('Failed to fetch data:', result.message);
      }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  const fetchLocationWiseTrend = async () => {
      try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${BASE_URL}/dashboard/get-location-trend`,{
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
          });
          const result = await response.json();
      if (result.statusCode === 200) {
        let locationsData = [];
        Object.entries(result.data).map(([pos, detail]) => (
          locationsData.push({ y: detail.activeDrivers, label: detail.location.locality })
        ));
        setLocationWiseTrend(locationsData);
      } else {
          console.error('Failed to fetch data:', result.message);
      }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  const fetchBatteryInventory = async () => {
      try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${BASE_URL}/dashboard/battery-inventory`,{
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
          });
          const result = await response.json();
          if (result.statusCode === 200) {
              setBatteryInventory([
                  { y: ((result.data.totalAvailableBatteries/result.data.totalBatteries)*100).toFixed(2), label: "Available" },
                  { y: ((result.data.totalServiceBatteries/result.data.totalBatteries)*100).toFixed(2), label: "In Service" },
                  { y: ((result.data.totalInRecoveryBatteries/result.data.totalBatteries)*100).toFixed(2), label: "In Recovery" },
                  { y: ((result.data.totalActiveBatteries/result.data.totalBatteries)*100).toFixed(2), label: "Leased" }
                ]);
          } else {
              console.error('Failed to fetch data:', result.message);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  const fetchLeadsSummary = async () => {
      try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${BASE_URL}/dashboard/leads-summary`,{
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
          });
          const result = await response.json();
          if (result.statusCode === 200) {
              setLeadsSummary([
                  { y: ((result.data.approved/result.data.totalLeadsCount)*100).toFixed(2), label: "Approved" },
                  { y: ((result.data.pending/result.data.totalLeadsCount)*100).toFixed(2), label: "Pending" },
                  { y: ((result.data.rejected/result.data.totalLeadsCount)*100).toFixed(2), label: "Rejected" },
                  { y: ((result.data.generated/result.data.totalLeadsCount)*100).toFixed(2), label: "Generated" }
                ]);
          } else {
              console.error('Failed to fetch data:', result.message);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  const fetchCollectionsData = async () => {
      try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${BASE_URL}/dashboard/collection-summary`,{
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
          });
          const result = await response.json();
          if (result.statusCode === 200) {
              setCollectionData([
                  { y: ((result.data.defaulted/result.data.total)*100).toFixed(2), label: "Defaulted" },
                  { y: ((result.data.delayed/result.data.total)*100).toFixed(2), label: "Delayed" },
                  { y: ((result.data.received/result.data.total)*100).toFixed(2), label: "Received" }
                ]);
          } else {
              console.error('Failed to fetch data:', result.message);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  const fetchHelpTicketsSummery = async () => {
      try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${BASE_URL}/dashboard/help-ticket-summary`,{
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
          });
          const result = await response.json();
          if (result.statusCode === 200) {
              setHelpTicketsData([
                  { y: ((result.data.pending/result.data.totalHelpTickets)*100).toFixed(2), label: "Pending" },
                  { y: ((result.data.inProgress/result.data.totalHelpTickets)*100).toFixed(2), label: "In Progress" },
                  { y: ((result.data.closed/result.data.totalHelpTickets)*100).toFixed(2), label: "Resolved" }
                ]);
          } else {
              console.error('Failed to fetch data:', result.message);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  const fetchBattryLocations = async () => {
      try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${BASE_URL}/dashboard/get-batterybox-locations`,{
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
          });
          const result = await response.json();
          if (result.statusCode === 200) {
            setBatteryStatus({
              'ignitionOn' : result.data.ignitionOn.length, 
              'ignitionOff' : result.data.ignitionOff.length, 
              'ideal' : result.data.ideal.length, 
              'noNetwork' : result.data.noNetwork.length
            });
            setMapMarkers(result.data);
          } else {
              console.error('Failed to fetch data:', result.message);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  useEffect(() => {
    //setMapMarkers(AllMarkesrs);
    fetchOverAllCollection();
    fetchLast12MonthCollection();
    fetchLeadsVsLeaseTrend();
    fetchLocationWiseTrend();
    fetchBatteryInventory();
    fetchLeadsSummary();
    fetchCollectionsData();
    fetchHelpTicketsSummery();
    fetchBattryLocations();
  }, []);
  return (
    <>
      <SubNavbar pageName="Dashboard" buttons={[]} />
      <div className="row">
        <div className="col-md-2">
          <div className="card stats-cards bg-total-battries text-white w-100">
            <div className="card-body">
              <h6 className="info-card-box-title text-center">Total Batteries</h6>
              <h5 className="info-card-box-number text-end">
                <span className="text-success"><small><FaCaretUp/></small></span>
                { overAllSummery?.totalBatteryBoxes?.toLocaleString() }
              </h5>
            </div>
          </div>
          <div className="card stats-cards bg-total-drivers text-white mt-2 w-100">
            <div className="card-body">
              <h6 className="info-card-box-title text-center">Total Drivers</h6>
              <h5 className="info-card-box-number text-end">
                <span className="text-danger"><small><FaCaretDown/></small></span>
                { overAllSummery?.totalDrivers?.toLocaleString() }
              </h5>
            </div>
          </div>
          <div className="card stats-cards bg-total-partners text-white mt-2 w-100">
            <div className="card-body">
              <h6 className="info-card-box-title text-center">Total Partners</h6>
              <h5 className="info-card-box-number text-end">
                <span className="text-danger"><small><FaCaretDown/></small></span>
                { overAllSummery?.totalPartners?.toLocaleString() }
              </h5>
            </div>
          </div>
          <div className="card stats-cards bg-total-revenve text-white mt-2 w-100">
            <div className="card-body">
              <h6 className="info-card-box-title text-center">Net Revenve</h6>
              <h5 className="info-card-box-number text-end">
                <span className="text-success"><small><FaCaretUp/></small></span>
                { overAllSummery?.netRevenue?.toLocaleString() }
              </h5>
            </div>
          </div>
          <div className="card stats-cards bg-total-locations text-white mt-2 w-100">
            <div className="card-body">
              <h6 className="info-card-box-title text-center">Active Locations</h6>
              <h5 className="info-card-box-number text-end">
                <span className="text-success"><small><FaCaretUp/></small></span>
                { overAllSummery?.totalActiveLocations?.toLocaleString() }
              </h5>
            </div>
          </div>
          <div className="card stats-cards bg-total-recovery text-white mt-2 w-100">
            <div className="card-body">
              <h6 className="info-card-box-title text-center">Recovery Requests</h6>
              <h5 className="info-card-box-number text-end">
                <span className="text-success"><small><FaCaretUp/></small></span>
                { overAllSummery?.totalRecoveryRequests?.toLocaleString() }
              </h5>
            </div>
          </div>
          <div className="card stats-cards bg-total-tickets text-white mt-2 w-100">
            <div className="card-body">
              <h6 className="info-card-box-title text-center">Help Tickets</h6>
              <h5 className="info-card-box-number text-end">
                <span className="text-success"><small><FaCaretUp/></small></span>
                { overAllSummery?.totalHelpTickets?.toLocaleString() }
              </h5>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card w-100 mb-2">
            <div className="card-body">
              <h5 className="text-start">Collections</h5>
              <ColumnChart reportData={last12MonthCollection}/>
            </div>
          </div>
          <div className="card w-100">
            <div className="card-body">
              <h5 className="text-start">Lead vs Lease Trend</h5>
              <LineChart reportData={leadsVsLeaseTrend}/>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card w-100">
            <div className="card-body">
              <h5 className="text-center">Battery Location</h5>              
              <LoadScript googleMapsApiKey={gMapApiKey}>
                <GoogleMap
                  mapContainerStyle={{ height: '432px', width: '100%' }}
                  zoom={6}
                  center={{lat: 28.7041, lng: 77.1025}}
                >
                  {mapMarkers?.ignitionOn?.map((markerData, index) => (
                    <Marker
                      key={index} // Add a unique key for each marker
                      position={{ lat: parseFloat(markerData.latitude), lng: parseFloat(markerData.longitude) }}    
                      icon={OnImg}
                      title={markerData.batteryBoxSerialNumber}                  
                      onClick={() => handleMarkerClick(markerData)} 
                    />
                  ))}
                  {mapMarkers?.ignitionOff?.map((markerData, index) => (
                    <Marker
                      key={index} // Add a unique key for each marker
                      position={{ lat: parseFloat(markerData.latitude), lng: parseFloat(markerData.longitude) }}    
                      icon={OffImg}
                      title={markerData.batteryBoxSerialNumber}                  
                      onClick={() => handleMarkerClick(markerData)} 
                    />
                  ))}
                  {mapMarkers?.ideal?.map((markerData, index) => (
                    <Marker
                      key={index} // Add a unique key for each marker
                      position={{ lat: parseFloat(markerData.latitude), lng: parseFloat(markerData.longitude) }}    
                      icon={IdealImg}
                      title={markerData.batteryBoxSerialNumber}                  
                      onClick={() => handleMarkerClick(markerData)} 
                    />
                  ))}
                  {mapMarkers?.noNetwork?.map((markerData, index) => (
                    <Marker
                      key={index} // Add a unique key for each marker
                      position={{ lat: parseFloat(markerData.latitude), lng: parseFloat(markerData.longitude) }}    
                      icon={NoNetworkImg}
                      title={markerData.batteryBoxSerialNumber}                  
                      onClick={() => handleMarkerClick(markerData)} 
                    />
                  ))}
                  {selectedMarker && (
                    <InfoWindow 
                      position={{ lat: parseFloat(selectedMarker.latitude), lng: parseFloat(selectedMarker.longitude) }}
                      onCloseClick={() => setSelectedMarker(null)}
                    >
                      <div>
                        <h6>{selectedMarker.batteryBoxSerialNumber}</h6>
                      </div>
                    </InfoWindow>
                  )}
                </GoogleMap>
              </LoadScript>
              <p className="status-imgs">
                <span>
                  <img src={OnImg} />Ignition ON - {batteryStatus.ignitionOn}
                </span>
                <span>
                  <img src={OffImg} />Ignition OFF - {batteryStatus.ignitionOff}
                </span>
              </p>
              <p className="status-imgs">
                <span>
                  <img src={IdealImg} />Ideal - {batteryStatus.ideal}
                </span>
                <span>
                  <img src={NoNetworkImg} />No Network - {batteryStatus.noNetwork}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>      
      <div className="row mt-2">
        <div className="col-md-12 col-xxxl-4">
          <div className="card w-100">
            <div className="card-body">
              <h5 className="text-start">Location wise Trend</h5>
              <BarChart reportData={locationWiseTrend}/>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-xxxl-8">
          <div className="row">
            <div className="col-md-6 col-xxxl-3 mt-2">
              <div className="card w-100">
                <div className="card-body">
                  <h5 className="text-center">Battery Inventory</h5>
                  <PieChart data={batteryInventory}/>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xxxl-3 mt-2">
              <div className="card w-100">
                <div className="card-body">
                  <h5 className="text-center">Leads</h5>
                  <PieChart data={leadsSummary}/>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xxxl-3 mt-2">
              <div className="card w-100">
                <div className="card-body">
                  <h5 className="text-center">Collections</h5>
                  <PieChart data={collectionData}/>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xxxl-3 mt-2">
              <div className="card w-100">
                <div className="card-body">
                  <h5 className="text-center">Help Tickets</h5>
                  <PieChart data={helpTicketsData}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Dashboard;
