import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LogOut = () => {
    const navigate = useNavigate();
    useEffect(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('loginUserData');
        navigate('/login');
        window.location.reload();
    }, [navigate]);
    return (
        <div>Logout</div>
    );
};
export default LogOut;

