import React, { useState, useEffect } from 'react';
import { Table, Modal, Input, message } from 'antd';
import SubNavbar from '../Header/SubNavbar';
import { FaToggleOn, FaToggleOff } from 'react-icons/fa';
import CustomButton from '../Button/CustomButton';
import { BASE_URL } from '../Constant/config';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PreLoader from '../PreLoader/PreLoader';
dayjs.extend(customParseFormat);

const ImmobilizeTable = () => {
  const [isLoader, setIsLoader] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [perPageItem, setPerPageItem] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filters, setFilters] = useState({
    batteryBoxSerialNumber: '',
    driver: '',
    partner: '',
    teamLead: '',
    location:  '',
  });

  useEffect(() => {
    fetchData(currentPage, perPageItem);
  }, []);

  const fetchData = async (page, pageSize) => {
    setIsLoader(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/battery-box/get-working-battery-boxes?limit=${pageSize}&page=${page}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        setDataSource(result.data);
        setTotalItems(result.total);
      } else {
        console.error('Failed to fetch data:', result.message);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.error('Error fetching data:', error);
    }
  };

  const handleDeviceOff = async (event, record) => {
    event.preventDefault();
    setIsLoader(true);
    console.log(record);
    try {
      const token = localStorage.getItem('token');
      const formDataSubmit = {
        "deviceImei"  : record.iotDevice.deviceImei,
        "type": "engineStop" 
      };
      const response = await fetch(`${BASE_URL}/iot/update-iot-ignition`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataSubmit),
      });
      const result = await response.json();
      message.success(result.message);
      fetchData(currentPage, perPageItem);
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      message.error('Error updateing ignition');
    }
  };

  const handleDeviceON = async (event, record) => {
    event.preventDefault();
    setIsLoader(true);
    console.log(record);
    try {
      const token = localStorage.getItem('token');
      const formDataSubmit = {
        "deviceImei": record.iotDevice.deviceImei,
        "type": "engineResume"
      };
      const response = await fetch(`${BASE_URL}/iot/update-iot-ignition`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataSubmit),
      });
      const result = await response.json();
      message.success(result.message);
      fetchData(currentPage, perPageItem);
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      message.error('Error updateing ignition');
    }
  };

  const handleExportClick = () => {
    console.log('handleExportClick');
  };

  const Buttons = [
    { text: 'Export', onClick: handleExportClick },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setCurrentPage(pagination.current);
    setPerPageItem(pagination.pageSize);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleFilterChange = (dataIndex, value) => {
    setFilters((prev) => ({
      ...prev,
      [dataIndex]: value,
    }));
  };

  const filteredDataSource = dataSource.filter((item) =>
    Object.keys(filters).every((key) =>
      item[key]?.toString().toLowerCase().includes(filters[key].toLowerCase())
    )
  );

  const columns = [
    {
      title: (
        <div>
          <div>Battery Code</div>
          <Input
            placeholder="Search Battery"
            value={filters.batteryBoxSerialNumber}
            onChange={(e) => handleFilterChange('batteryBoxSerialNumber', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'batteryBoxSerialNumber',
      width: 200,
      sorter: (a, b) => a.batteryBoxSerialNumber.localeCompare(b.batteryBoxSerialNumber),
    },
    {
      title: (
        <div>
          <div>Driver</div>
          <Input
            placeholder="Search Driver"
            value={filters.driver}
            onChange={(e) => handleFilterChange('driver', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'driver',
      width: 200,
      sorter: (a, b) => (a?.driver?.toString() || '').localeCompare(b?.driver?.toString() || ''),
      render: (_, record) => (
        <>
          {record.driver ? (
            record.driver.name
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: (
        <div>
          <div>Partner</div>
          <Input
            placeholder="Search Partner"
            value={filters.partner}
            onChange={(e) => handleFilterChange('partner', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'partner',
      width: 200,
      sorter: (a, b) => (a?.partner?.toString() || '').localeCompare(b?.partner?.toString() || ''),
      render: (_, record) => (
        <>
          {record.partner ? (
            record.partner.pocName
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: (
        <div>
          <div>TL</div>
          <Input
            placeholder="Search TL"
            value={filters.teamLead}
            onChange={(e) => handleFilterChange('teamLead', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'teamLead',
      width: 200,
      sorter: (a, b) => (a?.teamLead?.toString() || '').localeCompare(b?.teamLead?.toString() || ''),
      render: (_, record) => (
        <>
          {record.driver ? (
            record.driver?.teamLead?.name
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: (
        <div>
          <div>Location</div>
          <Input
            placeholder="Search Location"
            value={filters.location}
            onChange={(e) => handleFilterChange('location', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'location',
      width: 200,
      sorter: (a, b) => a.location - b.location,
      render: (_, record) => (
        <>
          {record.partner ? (
            record.partner?.locationR ? (`${record.partner?.locationR?.locality}, ${record.partner?.locationR?.city}, ${record.partner?.locationR?.state}, ${record.partner?.locationR?.pincode}`) : ('')
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: 'right',
      width: 180,
      render: (_, record) => (
        <span>
          {record.iotDevice.ignition ? (
            <a href='#' onClick={(event) => handleDeviceOff(event, record)} className='btn btn-danger'>
              <FaToggleOff style={{ cursor: 'pointer', marginRight: 8 }} /> Immobilize
            </a>
          ) : (            
            <a href='#' onClick={(event) => handleDeviceON(event, record)} className='btn btn-success'>
              <FaToggleOn style={{ cursor: 'pointer', marginRight: 8 }} /> Remobilize
            </a>
          )}
        </span>
      ),
    },
  ];

  return (
    <>
      {isLoader ? (
        <PreLoader/>
      ):''}
      <SubNavbar
        pageName="Immobilize Vehicle"
        buttons={Buttons}
      />
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive mt-2">
            <Table
              rowSelection={rowSelection}
              rowKey={(record) => record._id}
              columns={columns}
              dataSource={dataSource}
              onChange={onChange}
              width={700}
              scroll={{y: `calc(100vh - 280px)`}}
              pagination={{
                simple: false,
                size:"small",
                total: totalItems,
                showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                showQuickJumper: false,
                showLessItems: true,
                responsive:true,
                defaultPageSize:perPageItem,
                pageSizeOptions:[5,10,20,50,100],
                position: ['topRight'],
                onChange: (page, pageSize) => {
                  //console.log(page, pageSize);
                  fetchData(page, pageSize);
                }
              }}
              bordered
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ImmobilizeTable;
