import React, { useState, useEffect } from 'react';
import { Table, Select } from 'antd';
import colors from '../Constant/Color';
import SubNavbar from '../Header/SubNavbar';
import PreLoader from '../PreLoader/PreLoader';
import { BASE_URL } from '../Constant/config';


const ManageCollectionTable = () => {
    const { Option } = Select;
    const [isLoader, setIsLoader] = useState(true);
    const [selectedTab, setSelectedTab] = useState('Location');
    const [selectedFilter, setSelectedFilter] = useState('today');
    const [overAllCollectionData, setOverAllCollectionData] = useState({});
    const [perPageItemLocation, setPerPageItemLocation] = useState(5);
    const [currentPageLocation, setCurrentPageLocation] = useState(1);
    const [totalItemsLocation, setTotalItemsLocation] = useState(0);
    const [dataSourceLocation, setDataSourceLocation] = useState([]);
    const [perPageItemDriver, setPerPageItemDriver] = useState(5);
    const [currentPageDriver, setCurrentPageDriver] = useState(1);
    const [totalItemsDriver, setTotalItemsDriver] = useState(0);
    const [dataSourceDriver, setDataSourceDriver] = useState([]);
    const [perPageItemPartner, setPerPageItemPartner] = useState(5);
    const [currentPagePartner, setCurrentPagePartner] = useState(1);
    const [totalItemsPartner, setTotalItemsPartner] = useState(0);
    const [dataSourcePartner, setDataSourcePartner] = useState([]);
    const [perPageItemTeamLead, setPerPageItemTeamLead] = useState(5);
    const [currentPageTeamLead, setCurrentPageTeamLead] = useState(1);
    const [totalItemsTeamLead, setTotalItemsTeamLead] = useState(0);
    const [dataSourceTeamLead, setDataSourceTeamLead] = useState([]);
    const [perPageItemBatteryBox, setPerPageItemBatteryBox] = useState(5);
    const [currentPageBatteryBox, setCurrentPageBatteryBox] = useState(1);
    const [totalItemsBatteryBox, setTotalItemsBatteryBox] = useState(0);
    const [dataSourceBatteryBox, setDataSourceBatteryBox] = useState([]);

    const handelOnClickTab = async (currentTabname) => {
        setSelectedTab(currentTabname);
        if (currentTabname === 'Location') {
            fetchLocationCollection(currentPageLocation, perPageItemLocation);
        } else if (currentTabname === 'Driver') {
            fetchDriverCollection(currentPageDriver, perPageItemDriver);
        } else if (currentTabname === 'Partner') {
            fetchPartnerCollection(currentPagePartner, perPageItemPartner);
        } else if (currentTabname === 'TeamLead') {
            fetchTeamLeadCollection(currentPageTeamLead, perPageItemTeamLead);
        } else if (currentTabname === 'BatteryBox') {
            fetchBatteryBoxCollection(currentPageBatteryBox, perPageItemBatteryBox);
        }
    } 

    const fetchDataOnPagination = async (page, pageSize) =>{
        setIsLoader(true);
        if (selectedTab === 'Location') {
            fetchLocationCollection(page, pageSize);
        } else if (selectedTab === 'Driver') {
            fetchDriverCollection(page, pageSize);
        } else if (selectedTab === 'Partner') {
            fetchPartnerCollection(page, pageSize);
        } else if (selectedTab === 'TeamLead') {
            fetchTeamLeadCollection(page, pageSize);
        } else if (selectedTab === 'BatteryBox') {
            fetchBatteryBoxCollection(page, pageSize);
        }
    }

    const onChangeRangeOfSelect = (typeValue) => {
        setSelectedFilter(typeValue);
    }

    const fetchOverAllCollection = async (page, pageSize) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${BASE_URL}/collection/get-overall-collections`,{
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            });
            const result = await response.json();
        if (result.statusCode === 200) {
            setOverAllCollectionData(result.data);
        } else {
            console.error('Failed to fetch data:', result.message);
        }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchLocationCollection = async (page, pageSize) => {
      try {
        setIsLoader(true);
        const token = localStorage.getItem('token');
        let apiUrlStr = `collection/get-collection-by-location?limit=${pageSize}&page=${page}`;
        apiUrlStr = (selectedFilter !== '' ? apiUrlStr+`&type=${selectedFilter}` : apiUrlStr);
        const response = await fetch(`${BASE_URL}/${apiUrlStr}`,{
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        if (result.statusCode === 200) {
          setDataSourceLocation(result.data);
          setTotalItemsLocation(result.totalCount);
          setIsLoader(false);
        } else {
          console.error('Failed to fetch data:', result.message);
          setIsLoader(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoader(false);
      }
    };

    const fetchDriverCollection = async (page, pageSize) => {
      try {
        setIsLoader(true);
        const token = localStorage.getItem('token');
        let apiUrlStr = `collection/get-collection-by-driver?limit=${pageSize}&page=${page}`;
        apiUrlStr = (selectedFilter !== '' ? apiUrlStr+`&type=${selectedFilter}` : apiUrlStr);
        const response = await fetch(`${BASE_URL}/${apiUrlStr}`,{
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        if (result.statusCode === 200) {
          setDataSourceDriver(result.data);
          setTotalItemsDriver(result.pagination.totalItems);
          setIsLoader(false);
        } else {
          console.error('Failed to fetch data:', result.message);
          setIsLoader(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoader(false);
      }
    };

    const fetchPartnerCollection = async (page, pageSize) => {
      try {
        setIsLoader(true);
        const token = localStorage.getItem('token');
        let apiUrlStr = `collection/get-collection-by-partner?limit=${pageSize}&page=${page}`;
        apiUrlStr = (selectedFilter !== '' ? apiUrlStr+`&type=${selectedFilter}` : apiUrlStr);
        const response = await fetch(`${BASE_URL}/${apiUrlStr}`,{
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        if (result.statusCode === 200) {
          setDataSourcePartner(result.data);
          setTotalItemsPartner(result.pagination.totalItems);
          setIsLoader(false);
        } else {
          console.error('Failed to fetch data:', result.message);
          setIsLoader(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoader(false);
      }
    };

    const fetchTeamLeadCollection = async (page, pageSize) => {
      try {
        setIsLoader(true);
        const token = localStorage.getItem('token');
        let apiUrlStr = `collection/get-collection-by-leader?limit=${pageSize}&page=${page}`;
        apiUrlStr = (selectedFilter !== '' ? apiUrlStr+`&type=${selectedFilter}` : apiUrlStr);
        const response = await fetch(`${BASE_URL}/${apiUrlStr}`,{
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        if (result.statusCode === 200) {
          setDataSourceTeamLead(result.data);
          setTotalItemsTeamLead(result.pagination.totalItems);
          setIsLoader(false);
        } else {
          console.error('Failed to fetch data:', result.message);
          setIsLoader(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoader(false);
      }
    };

    const fetchBatteryBoxCollection = async (page, pageSize) => {
      try {
        setIsLoader(true);
        const token = localStorage.getItem('token');
        let apiUrlStr = `collection/get-collection-by-battery-box?limit=${pageSize}&page=${page}`;
        apiUrlStr = (selectedFilter !== '' ? apiUrlStr+`&type=${selectedFilter}` : apiUrlStr);
        const response = await fetch(`${BASE_URL}/${apiUrlStr}`,{
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        if (result.statusCode === 200) {
          setDataSourceBatteryBox(result.data);
          setTotalItemsBatteryBox(result.pagination.totalItems);
          setIsLoader(false);
        } else {
          console.error('Failed to fetch data:', result.message);
          setIsLoader(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoader(false);
      }
    };

    const columnsLocation = [
        {
            title: 'Locations',
            dataIndex: 'location',
            sorter: (a, b) => a.location.localeCompare(b.location),
            render: (_, record) => (
                <strong>{record.location.locality}<br></br>{record.location.city}<br></br>{record.location.state}<br></br>{record.location.pincode}</strong>
            ),
        },
        {
            title: 'Collections',
            dataIndex: 'total_collection',
            sorter: (a, b) => a.total_collection.localeCompare(b.total_collection),
        },
    ];

    const columnsDriver = [
        {
            title: 'Driver Code',
            dataIndex: 'driver.uniqueCode',
            sorter: (a, b) => a.driver.uniqueCode.localeCompare(b.driver.uniqueCode),
            width: 200,
            render: (_, record) => (
                <span>{record.driver.uniqueCode}</span>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'driver.name',
            sorter: (a, b) => a.driver.name.localeCompare(b.driver.name),
            width: 200,
            render: (_, record) => (
                <span>{record.driver.name}</span>
            ),
        },
        {
            title: 'Mobile',
            dataIndex: 'driver.mobile',
            sorter: (a, b) => a.driver.mobile.localeCompare(b.driver.mobile),
            width: 200,
            render: (_, record) => (
                <span>{record.driver.mobile}</span>
            ),
        },
        {
            title: 'Associate TL',
            dataIndex: 'teamLead',
            sorter: (a, b) => a.teamLead.localeCompare(b.teamLead),
            width: 200,
            render: (_, record) => (
                <span>{record.driver.teamLead ? record.driver.teamLead.name : '-'}</span>
            ),
        },
        {
            title: 'Associate Partner',
            dataIndex: 'partner',
            sorter: (a, b) => a.partner.localeCompare(b.partner),
            width: 200,
            render: (_, record) => (
                <span>{record.driver.partner ? record.driver.partner.companyName : '-'}</span>
            ),
        },
        {
            title: 'Location',
            dataIndex: 'location',
            sorter: (a, b) => a.location.localeCompare(b.location),
            width: 200,
            render: (_, record) => (
                record.driver.location ? <span>{record.driver.location.locality}<br></br>{record.driver.location.city}<br></br>{record.driver.location.state}<br></br>{record.driver.location.pincode}</span> : '-'
            ),
        },
        {
            title: 'Batteries',
            dataIndex: 'batteries',
            sorter: (a, b) => a.batteries.localeCompare(b.batteries),
            width: 200,
            render: (_, record) => (
                <span>{record.batteries ? record.batteries : '0'}</span>
            ),
        },

        {
            title: 'Amount Payable',
            dataIndex: 'total_amount_payable',
            sorter: (a, b) => a.total_amount_payable.localeCompare(b.total_amount_payable),
            width: 200,
            render: (_, record) => (
                <span>{record.total_amount_payable ? record.total_amount_payable : '0'}</span>
            ),
        },

        {
            title: 'Amount Received',
            dataIndex: 'total_amount_received',
            sorter: (a, b) => a.total_amount_received.localeCompare(b.total_amount_received),
            width: 200,
            render: (_, record) => (
                <span>{record.total_amount_received ? record.total_amount_received : '0'}</span>
            ),
        },

        {
            title: 'Amount Pending',
            dataIndex: 'total_amount_pending',
            sorter: (a, b) => a.total_amount_pending.localeCompare(b.total_amount_pending),
            width: 200,
            render: (_, record) => (
                <span>{record.total_amount_pending ? record.total_amount_pending : '0'}</span>
            ),
        },
    ];

    const columnsPartner = [
        {
            title: 'Partner Code',
            dataIndex: 'partner.uniqueCode',
            sorter: (a, b) => a.partner.uniqueCode.localeCompare(b.partner.uniqueCode),
            width: 200,
            render: (_, record) => (
                <span>{record.partner.uniqueCode}</span>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'partner.companyName',
            sorter: (a, b) => a.partner.companyName.localeCompare(b.partner.companyName),
            width: 200,
            render: (_, record) => (
                <span>{record.partner.companyName}</span>
            ),
        },
        {
            title: 'Mobile',
            dataIndex: 'partner.mobile',
            sorter: (a, b) => a.partner.mobile.localeCompare(b.partner.mobile),
            width: 200,
            render: (_, record) => (
                <span>{record.partner.mobile}</span>
            ),
        },
        {
            title: 'Associate TL',
            dataIndex: 'teamLead',
            sorter: (a, b) => a.teamLead.localeCompare(b.teamLead),
            width: 200,
            render: (_, record) => (
                record.partner.teamLead ? <span>{record.partner.teamLead.name}</span> : '-'
            ),
        },
        {
            title: 'Location',
            dataIndex: 'locationR',
            sorter: (a, b) => a.locationR.localeCompare(b.locationR),
            width: 200,
            render: (_, record) => (
                record.partner.locationR ? <span>{record.partner.locationR.locality}<br></br>{record.partner.locationR.city}<br></br>{record.partner.locationR.state}<br></br>{record.partner.locationR.pincode}</span> : '-'
            ),
        },
        {
            title: 'Active Batteries',
            dataIndex: 'activeBatteries',
            sorter: (a, b) => a.activeBatteries.localeCompare(b.activeBatteries),
            width: 200,
            render: (_, record) => (
                record.activeBatteries ? record.activeBatteries : '0'
            ),
        },
        {
            title: 'Amount Payable',
            dataIndex: 'total_amount_payable',
            sorter: (a, b) => a.total_amount_payable.localeCompare(b.total_amount_payable),
            width: 200,
            render: (_, record) => (
                record.total_amount_payable ? record.total_amount_payable : '0'
            ),
        },
        {
            title: 'Amount Received',
            dataIndex: 'total_amount_received',
            sorter: (a, b) => a.total_amount_received.localeCompare(b.total_amount_received),
            width: 200,
            render: (_, record) => (
                record.total_amount_received ? record.total_amount_received : '0'
            ),
        },
        {
            title: 'Amount Pending',
            dataIndex: 'total_amount_pending',
            sorter: (a, b) => a.total_amount_pending.localeCompare(b.total_amount_pending),
            width: 200,
            render: (_, record) => (
                record.total_amount_pending ? record.total_amount_pending : '0'
            ),
        },
    ];

    const columnsTeamLead = [
        {
            title: 'TL Code',
            dataIndex: 'leader.uniqueCode',
            sorter: (a, b) => a.leader.uniqueCode.localeCompare(b.leader.uniqueCode),
            width: 200,
            render: (_, record) => (
                record.leader.uniqueCode ? record.leader.uniqueCode : '-'
            ),
        },
        {
            title: 'Name',
            dataIndex: 'leader.name',
            sorter: (a, b) => a.leader.name.localeCompare(b.leader.name),
            width: 200,
            render: (_, record) => (
                record.leader.name ? record.leader.name : '-'
            ),
        },
        {
            title: 'Mobile',
            dataIndex: 'leader.mobile',
            sorter: (a, b) => a.leader.mobile.localeCompare(b.leader.mobile),
            width: 200,
            render: (_, record) => (
                record.leader.mobile ? record.leader.mobile : '-'
            ),
        },
        {
            title: 'Location',
            dataIndex: 'location',
            sorter: (a, b) => a.location.localeCompare(b.location),
            width: 200,
            render: (_, record) => (
                record.leader.location ? <span>{record.leader.location.locality}<br></br>{record.leader.location.city}<br></br>{record.leader.location.state}<br></br>{record.leader.location.pincode}</span> : '-'
            ),
        },
        {
            title: 'Active Batteries',
            dataIndex: 'activeBatteries',
            sorter: (a, b) => a.activeBatteries.localeCompare(b.activeBatteries),
            width: 200,
            render: (_, record) => (
                record.activeBatteries ? record.activeBatteries : '0'
            ),
        },
        {
            title: 'Amount Payable',
            dataIndex: 'total_amount_payable',
            sorter: (a, b) => a.total_amount_payable.localeCompare(b.total_amount_payable),
            width: 200,
            render: (_, record) => (
                record.total_amount_payable ? record.total_amount_payable : '0'
            ),
        },
        {
            title: 'Amount Received',
            dataIndex: 'total_amount_received',
            sorter: (a, b) => a.total_amount_received.localeCompare(b.total_amount_received),
            width: 200,
            render: (_, record) => (
                record.total_amount_received ? record.total_amount_received : '0'
            ),
        },
        {
            title: 'Amount Pending',
            dataIndex: 'total_amount_pending',
            sorter: (a, b) => a.total_amount_pending.localeCompare(b.total_amount_pending),
            width: 200,
            render: (_, record) => (
                record.total_amount_pending ? record.total_amount_pending : '0'
            ),
        },
    ];

    const columnsBatteryBox = [
        {
            title: 'Battery Code',
            dataIndex: 'batteryBox.batteryBoxSerialNumber',
            sorter: (a, b) => a.batteryBox.batteryBoxSerialNumber.localeCompare(b.batteryBox.batteryBoxSerialNumber),
            width: 150,
            render: (_, record) => (
                record.batteryBox.batteryBoxSerialNumber ? record.batteryBox.batteryBoxSerialNumber : '-'
            ),
        },
        {
            title: 'Driver',
            dataIndex: 'driver',
            sorter: (a, b) => a.driver.localeCompare(b.driver),
            width: 150,
            render: (_, record) => (
                record.driver ? record.driver : '-'
            ),
        },
        {
            title: 'Partner',
            dataIndex: 'partner',
            sorter: (a, b) => a.partner.localeCompare(b.partner),
            width: 150,
            render: (_, record) => (
                record.partner ? record.partner : '-'
            ),
        },
        {
            title: 'TL',
            dataIndex: 'teamLead',
            sorter: (a, b) => a.teamLead.localeCompare(b.teamLead),
            width: 150,
            render: (_, record) => (
                record.teamLead ? record.teamLead : '-'
            ),
        },
        {
            title: 'Location',
            dataIndex: 'location',
            sorter: (a, b) => a.location.localeCompare(b.location),
            width: 150,
            render: (_, record) => (
                record.location ? <span>{record.location.locality}<br></br>{record.location.city}<br></br>{record.location.state}<br></br>{record.location.pincode}</span> : '-'
            ),
        },
        {
            title: 'Amount Received',
            dataIndex: 'total_amount_received',
            sorter: (a, b) => a.total_amount_received.localeCompare(b.total_amount_received),
            width: 200,
            render: (_, record) => (
                record.total_amount_received ? record.total_amount_received : '0'
            ),
        },
        {
            title: 'Amount Pending',
            dataIndex: 'total_amount_pending',
            sorter: (a, b) => a.total_amount_pending.localeCompare(b.total_amount_pending),
            width: 200,
            render: (_, record) => (
                record.total_amount_pending ? record.total_amount_pending : '0'
            ),
        },
    ];

    const buttonStyle = {
        outline: 'none',
        border: 'none',
        margin: '0.75rem',
        width:"150px",
        height:"50px",
        color:colors.primary
    };

    const activeStyle = {
        backgroundColor: '#CDCDCD', 
        color: colors.primary, 
        width:"150px",
        height:"50px"
    };

    useEffect(() => {
      handelOnClickTab(selectedTab);
      fetchOverAllCollection();
    }, []);

    return (
        <>
            {isLoader ? (
            <PreLoader/>
            ):''}
            <SubNavbar
            pageName="Manage Collections"
            buttons={[]}
            />
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className='col-12 col-sm-4'>
                            <div class="card text-bg-primary">
                                <div class="card-body">
                                    <h5 class="card-title">Today's Collection</h5>
                                    <h6 class="card-text">{overAllCollectionData.todays_collection} INR</h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-4'>
                            <div class="card text-bg-primary">
                                <div class="card-body">
                                    <h5 class="card-title">Current week's Collection</h5>
                                    <h6 class="card-text">{overAllCollectionData.current_week_collection} INR</h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-4'>
                            <div class="card text-bg-primary">
                                <div class="card-body">
                                    <h5 class="card-title">Current Month's Collection</h5>
                                    <h6 class="card-text">{overAllCollectionData.current_month_collection} INR</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
            <div className='mt-4' style={{ backgroundColor: "#F1F3F4" }}>
              <div>
                <div className="row">
                    <div className="d-flex">
                        <button
                            style={{
                                ...buttonStyle,
                                ...(selectedTab === 'Location' ? activeStyle : {}),
                            }}
                            onClick={() => handelOnClickTab('Location')}>
                            Location
                        </button>
                        <button
                            style={{
                                ...buttonStyle,
                                ...(selectedTab === 'Driver' ? activeStyle : {}),
                            }}
                            onClick={() => handelOnClickTab('Driver')}>
                            Driver
                        </button>
                        <button
                            style={{
                                ...buttonStyle,
                                ...(selectedTab === 'Partner' ? activeStyle : {}),
                            }}
                            onClick={() => handelOnClickTab('Partner')}>
                            Partner
                        </button>
                        <button
                            style={{
                                ...buttonStyle,
                                ...(selectedTab === 'TeamLead' ? activeStyle : {}),
                            }}
                            onClick={() => handelOnClickTab('TeamLead')}>
                            Team Lead
                        </button>
                        <button
                            className="d-none"
                            style={{
                                ...buttonStyle,
                                ...(selectedTab === 'BatteryBox' ? activeStyle : {}),
                            }}
                            onClick={() => handelOnClickTab('BatteryBox')}>
                            Battery Box
                        </button>
                        <Select
                          showSearch
                          placeholder="Select"
                          className="ml-auto align-self-center"
                          optionFilterProp="label"  // Searches within the value prop
                          style={{ width: '100px' }}
                          onChange={onChangeRangeOfSelect}
                          filterOption={(input, option) =>
                            option?.children.toLowerCase().includes(input.toLowerCase())
                          }
                        >  
                          <Option value="">--Select--</Option>
                          <Option value="today">Today</Option>
                          <Option value="week">Weekly</Option>
                          <Option value="month">Monthly</Option>
                        </Select>
                    </div>
                    <div className="col-sm-12">
                        <div className="table-responsive mt-2">  
                            <>  
                                {selectedTab == 'Location'?  (
                                    <Table
                                    rowKey={(record) => record.location._id}
                                    columns={columnsLocation}
                                    dataSource={dataSourceLocation}
                                    scroll={{y: `calc(100vh - 380px)`}}
                                    pagination={{
                                      simple: false,
                                      size:"small",
                                      total: totalItemsLocation,
                                      showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                      showSizeChanger: true,
                                      showQuickJumper: false,
                                      showLessItems: true,
                                      responsive:true,
                                      defaultPageSize:perPageItemLocation,
                                      pageSizeOptions:[5,10,20,50,100],
                                      position: ['topRight'],
                                      onChange: (page, pageSize) => {
                                        fetchDataOnPagination(page, pageSize);
                                      }
                                    }}
                                    bordered
                                  />
                                ) : ''} 
                                {selectedTab == 'Driver'?  (
                                    <Table
                                    rowKey={(record) => record.driver._id}
                                    columns={columnsDriver}
                                    dataSource={dataSourceDriver}
                                    scroll={{y: `calc(100vh - 380px)`}}
                                    pagination={{
                                      simple: false,
                                      size:"small",
                                      total: totalItemsDriver,
                                      showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                      showSizeChanger: true,
                                      showQuickJumper: false,
                                      showLessItems: true,
                                      responsive:true,
                                      defaultPageSize:perPageItemDriver,
                                      pageSizeOptions:[5,10,20,50,100],
                                      position: ['topRight'],
                                      onChange: (page, pageSize) => {
                                        fetchDataOnPagination(page, pageSize);
                                      }
                                    }}
                                    bordered
                                  />
                                ) : ''} 
                                {selectedTab == 'Partner'?  (
                                    <Table
                                    rowKey={(record) => record.partner._id}
                                    columns={columnsPartner}
                                    dataSource={dataSourcePartner}
                                    scroll={{y: `calc(100vh - 380px)`}}
                                    pagination={{
                                      simple: false,
                                      size:"small",
                                      total: totalItemsPartner,
                                      showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                      showSizeChanger: true,
                                      showQuickJumper: false,
                                      showLessItems: true,
                                      responsive:true,
                                      defaultPageSize:perPageItemPartner,
                                      pageSizeOptions:[5,10,20,50,100],
                                      position: ['topRight'],
                                      onChange: (page, pageSize) => {
                                        fetchDataOnPagination(page, pageSize);
                                      }
                                    }}
                                    bordered
                                  />
                                ) : ''} 
                                {selectedTab == 'TeamLead'?  (
                                    <Table
                                    rowKey={(record) => record.leader._id}
                                    columns={columnsTeamLead}
                                    dataSource={dataSourceTeamLead}
                                    scroll={{y: `calc(100vh - 380px)`}}
                                    pagination={{
                                      simple: false,
                                      size:"small",
                                      total: totalItemsTeamLead,
                                      showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                      showSizeChanger: true,
                                      showQuickJumper: false,
                                      showLessItems: true,
                                      responsive:true,
                                      defaultPageSize:perPageItemTeamLead,
                                      pageSizeOptions:[5,10,20,50,100],
                                      position: ['topRight'],
                                      onChange: (page, pageSize) => {
                                        fetchDataOnPagination(page, pageSize);
                                      }
                                    }}
                                    bordered
                                  />
                                ) : ''} 
                                {selectedTab == 'BatteryBox'?  (
                                    <Table
                                    rowKey={(record) => record.batteryBox.id}
                                    columns={columnsBatteryBox}
                                    dataSource={dataSourceBatteryBox}
                                    scroll={{y: `calc(100vh - 380px)`}}
                                    pagination={{
                                      simple: false,
                                      size:"small",
                                      total: totalItemsBatteryBox,
                                      showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                      showSizeChanger: true,
                                      showQuickJumper: false,
                                      showLessItems: true,
                                      responsive:true,
                                      defaultPageSize:perPageItemBatteryBox,
                                      pageSizeOptions:[5,10,20,50,100],
                                      position: ['topRight'],
                                      onChange: (page, pageSize) => {
                                        fetchDataOnPagination(page, pageSize);
                                      }
                                    }}
                                    bordered
                                  />
                                ) : ''}
                            </>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </>
    );
};

export default ManageCollectionTable;
