import React from 'react';
import { useNavigate } from 'react-router-dom';
import colors from '../components/Constant/Color';
import { Tab, Nav } from 'react-bootstrap';
import UserRolesTable from '../components/Table/UserRolesTable';

function UserRoles() {
  const navigate = useNavigate();

  const handleTabChange = (key, event) => {
    console.log(key);
    console.log(event);
    if (key === "promotionalContent") {
      navigate(`/settings/promotional-content`);
    } else if(key === "notifications"){
      navigate(`/settings/notifications`);
    } else if(key === "userRoles"){
      navigate(`/settings/user-roles`);
    } else if(key === "offers"){
      navigate(`/settings/offers`);
    } else if(key === "issues"){
      navigate(`/settings/issues`);
    } else if(key === "global"){
      navigate(`/settings/global-settings`);
    }
  }

  return (
    <>
    <div className='col-md-12'>
      <h6 className="my-4">Manage Settings</h6>
      <Tab.Container defaultActiveKey="userRoles" onSelect={handleTabChange}>
        <div className='row'>
          <div className='col-md-12'>
            <Nav variant="pills" className="flex-row tabbar-top" style={{backgroundColor: colors.primary}}>
              <Nav.Item>
                <Nav.Link eventKey="global">Global</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="issues">Issues</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="offers">Offers</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="userRoles" to="/user-roles">User Roles</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="notifications">Notifications</Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="promotionalContent">Promotional Content</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="custom-tab-content">
              <Tab.Pane
              eventKey="userRoles" style={{backgroundColor:"transparent"}}>
                <UserRolesTable/>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </div>
    </>
  );
}

export default UserRoles;
