import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Table, Modal, Form, Input, message, Select, DatePicker, Anchor } from 'antd';
import SubNavbar from '../Header/SubNavbar';
import { FaEdit, FaTrash, FaSave } from 'react-icons/fa';
import { AiOutlineAppstore } from 'react-icons/ai';
import CustomButton from '../Button/CustomButton';
import colors from '../Constant/Color';
import { BASE_URL } from '../Constant/config';
import Swal from 'sweetalert2'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PreLoader from '../PreLoader/PreLoader';
dayjs.extend(customParseFormat);

let initialInveontries = [];
initialInveontries['BatteryBox'] = [];
initialInveontries['Battery'] = [];
initialInveontries['IOT'] = [];
let allstatusText = [];
allstatusText['recovery'] = 'Recovery';
allstatusText['active'] = 'Active';
allstatusText['pending_for_allocation'] = 'Pending For Allocation';
allstatusText['partner_inventory'] = 'Partner Inventory';
allstatusText['sold_out'] = 'Sold Out';
allstatusText['service'] = 'Service';
allstatusText['eliminated'] = 'Eliminated';
let allstatusColors = [];
allstatusColors['recovery'] = '#8A0102';
allstatusColors['active'] = '#248B22';
allstatusColors['pending_for_allocation'] = '#02469B';
allstatusColors['partner_inventory'] = '#FF4500';
allstatusColors['sold_out'] = '#A9A9A9';
allstatusColors['service'] = '#02469B';
allstatusColors['eliminated'] = '#A9A9A9';

const InventoryDataTable = () => {
  const contentToPrint = useRef(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [batteryForm] = Form.useForm();
  const [batteryBoxForm] = Form.useForm();
  const [isLoader, setIsLoader] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isQrCodeModalVisible, setIsQrCodeModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTab, setSelectedTab] = useState('BatteryBox');
  const [currentRecord, setCurrentRecord] = useState(null);
  const [perPageItemBatteryBox, setPerPageItemBatteryBox] = useState(5);
  const [currentPageBatteryBox, setCurrentPageBatteryBox] = useState(1);
  const [totalItemsBatteryBox, setTotalItemsBatteryBox] = useState(0);
  const [dataSourceBatteryBox, setDataSourceBatteryBox] = useState([]);
  const [perPageItemBattery, setPerPageItemBattery] = useState(5);
  const [currentPageBattery, setCurrentPageBattery] = useState(1);
  const [totalItemsBattery, setTotalItemsBattery] = useState(0);
  const [dataSourceBattery, setDataSourceBattery] = useState([]);
  const [perPageItemIOT, setPerPageItemIOT] = useState(5);
  const [currentPageIOT, setCurrentPageIOT] = useState(1);
  const [totalItemsIOT, setTotalItemsIOT] = useState(0);
  const [dataSourceIOT, setDataSourceIOT] = useState([]);
  const [allManufacturers, setAllManufacturers] = useState([]);
  const [allAvailableBattries, setAllAvailableBattries] = useState([]);
  const [allAvailableIOT, setAvailableIOT] = useState([]);
  const [modalTitleOfAction, setModalTitleOfAction] = useState('Add');
  const [batteryBoxDetails, setBatteryBoxDetails] = useState('');
  const { Option } = Select;

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    if (selectedTab === 'BatteryBox') {
      setCurrentPageBatteryBox(pagination.current);
      setPerPageItemBatteryBox(pagination.pageSize);
    } else if (selectedTab === 'Battery') { 
      setCurrentPageBattery(pagination.current);
      setPerPageItemBattery(pagination.pageSize);
    } else if (selectedTab === 'IOT') { 
      setCurrentPageIOT(pagination.current);
      setPerPageItemIOT(pagination.pageSize);
    }
  };

  const getAllManufacturers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/admin/get-users?role=manufacturer&limit=50000&page=1`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && Array.isArray(result.data)) {
        setAllManufacturers(result.data);
        setIsLoader(false);
      } else {
        console.error('Unexpected data format:', result);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const getAllAvailableBattries = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/battery/get-available-batteries`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && Array.isArray(result.data)) {
        setAllAvailableBattries(result.data);
      } else {
        console.error('Unexpected data format:', result);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const getAllAvailableIOT = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/iot/get-available-iot-vehicles`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && Array.isArray(result.data)) {
        setAvailableIOT(result.data);
      } else {
        console.error('Unexpected data format:', result);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const handelOnClickTab = async (currentTabname) => {
    setSelectedTab(currentTabname);
    if (currentTabname === 'BatteryBox') {
      fetchBatteryBoxData(currentPageBatteryBox, perPageItemBatteryBox);
    } else if (currentTabname === 'Battery') {
      fetchBatteryData(currentPageBattery, perPageItemBattery);
    } else if (currentTabname === 'IOT') {
      fetchIOTData(currentPageIOT, perPageItemIOT);
    }
  } 

  const fetchDataOnPagination = async (page, pageSize) =>{
    if (selectedTab === 'BatteryBox') {
      fetchBatteryBoxData(page, pageSize);
    } else if (selectedTab === 'Battery') {
      fetchBatteryData(page, pageSize);
    } else if (selectedTab === 'IOT') {
      fetchIOTData(page, pageSize);
    }
  }

  const fetchBatteryData = async (page, pageSize) => {
    try {
      setIsLoader(true);
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/battery/get-batteries?limit=${pageSize}&page=${page}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        initialInveontries['Battery'] = result.data.batteries;
        setDataSourceBattery(result.data.batteries);
        setTotalItemsBattery(result.data.totalBatteries);
        setIsLoader(false);
      } else {
        console.error('Failed to fetch data:', result.message);
        setIsLoader(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoader(false);
    }
  };

  const fetchIOTData = async (page, pageSize) => {
    try {
      setIsLoader(true);
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/iot/fetch-local-iot-vehicles?limit=${pageSize}&page=${page}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        initialInveontries['IOT'] = result.data;
        setDataSourceIOT(result.data);
        setTotalItemsIOT(result.totalCount);
        setIsLoader(false);
      } else {
        console.error('Failed to fetch data:', result.message);
        setIsLoader(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoader(false);
    }
  };

  const fetchBatteryBoxData = async (page, pageSize) => {
    try {
      setIsLoader(true);
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/battery-box/get-battery-boxes?limit=${pageSize}&page=${page}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        initialInveontries['BatteryBox'] = result.data;
        setDataSourceBatteryBox(result.data);
        setTotalItemsBatteryBox(result.totalCount);
        setIsLoader(false);
      } else {
        console.error('Failed to fetch data:', result.message);
        setIsLoader(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoader(false);
    }
  };

  const handleEdit = (event, record) => {
    event.preventDefault();
    setCurrentRecord(record);
    if (selectedTab === 'BatteryBox') {
      batteryBoxForm.resetFields();
      setModalTitleOfAction('Update Map IOT to Battery');          
      batteryBoxForm.setFieldsValue({ 
        iotDevice: record.iotDevice ? record.iotDevice._id ? record.iotDevice._id : '' : '',
        battery: record.battery ? record.battery._id ? record.battery._id : '' : ''
      });
    } else if (selectedTab === 'Battery') { 
      batteryForm.resetFields();
      setModalTitleOfAction('Edit Battery');
      batteryForm.setFieldsValue({ 
        serialNumber: record.serialNumber,
        manufacturedDateNotFormated: dayjs(dayjs(record.manufacturedDate).format('DD/MM/YYYY'), 'DD/MM/YYYY'),
        specifications: record.specifications,
        manufacturer: record.manufacturer ? record.manufacturer._id ? record.manufacturer._id : '' : ''
      });
    }
    setIsEditing(true);
    setIsModalVisible(true);
  };

  const handleDelete = async (event, record) => {
    event.preventDefault();
    let deleteDevices = { devices: [] };
    if(record?._id){
      deleteDevices.devices = [record._id];
    } else {      
      if(selectedRowKeys.length !== 0){
        deleteDevices.devices = selectedRowKeys;
      }
    }    
    if(deleteDevices.devices.length > 0) {
      Swal.fire({
        title: "Are you sure? ",
        html: "You want to delete <br/>You won't be able to revert this!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
            confirmButton: "btn btn-success mx-1",
            cancelButton: "btn btn-danger mx-1"
        },
        buttonsStyling: !1,
      }).then(async function (t) {
          if(t.isConfirmed === true){
            setIsLoader(true);
            const token = localStorage.getItem('token');
            if(selectedTab === 'BatteryBox'){
              const response = await fetch(`${BASE_URL}/battery-box/delete-battery-box/`, {
                method: 'PUT',
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(deleteDevices),
              });
              const result = await response.json();
              if(result.statusCode === 200){
                message.success(result.message);
                fetchBatteryBoxData(currentPageBatteryBox, perPageItemBatteryBox);
                getAllAvailableBattries();
                getAllAvailableIOT();
                setIsLoader(false);
              } else {
                message.error('Error');
                setIsLoader(false);
              }              
            } else if(selectedTab === 'Battery'){
              const response = await fetch(`${BASE_URL}/battery/delete-battery/`, {
                method: 'PUT',
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(deleteDevices),
              });
              const result = await response.json();
              if(result.statusCode === 200){
                message.success(result.message);
                fetchBatteryData(currentPageBattery, perPageItemBattery);
                getAllAvailableBattries();
                getAllAvailableIOT();
                setIsLoader(false);
              } else {
                message.error('Error');
                setIsLoader(false);
              }              
            } else if(selectedTab === 'IOT'){
              const response = await fetch(`${BASE_URL}/iot/delete-iot-vehicles`, {
                method: 'PUT',
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(deleteDevices),
              });
              const result = await response.json();
              if(result.statusCode === 200){
                message.success(result.message);
                fetchIOTData(currentPageIOT, perPageItemIOT);
                getAllAvailableBattries();
                getAllAvailableIOT();
                setIsLoader(false);
              } else {
                message.error('Error');
                setIsLoader(false);
              }              
            }
          }
      });
    }
  };

  const handleAddClick = () => {
    setCurrentRecord(null);
    if (selectedTab === 'BatteryBox') {
      setModalTitleOfAction('Map IOT to Battery');          
      batteryBoxForm.resetFields();
    } else if (selectedTab === 'Battery') {
      setModalTitleOfAction('Add Battery');           
      batteryForm.resetFields();
    }
    setIsEditing(false);
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    setIsLoader(true);
    if (selectedTab === 'BatteryBox' && isEditing === false) {
      try {
        const token = localStorage.getItem('token');
        const formData = batteryBoxForm.getFieldsValue(true);  
        console.log(formData);
        const response = await fetch(`${BASE_URL}/battery-box/create-battery-box`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        const result = await response.json();
        if (result.statusCode === 200 || result.statusCode === 201) {
          message.success('Battery box created successfully');
          fetchBatteryBoxData(currentPageBatteryBox, perPageItemBatteryBox);            
          getAllAvailableBattries();
          getAllAvailableIOT();
          setIsModalVisible(false);
          setIsLoader(false);
        } else {
          message.error('Failed to create battery box - ' + result.message);
          setIsLoader(false);
        }
      } catch (error) {
        message.error('Error creating battery box');
        setIsLoader(false);
      }
    } else if(selectedTab === 'BatteryBox' && isEditing === true){
      try {
        const token = localStorage.getItem('token');
        const battryBoxIdEdit = currentRecord._id;
        const formData = batteryBoxForm.getFieldsValue(true); 
        const response = await fetch(`${BASE_URL}/battery-box/update-battery-box/${battryBoxIdEdit}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        const result = await response.json();
        if (result.statusCode === 200 || result.statusCode === 201) {
          message.success('Battery box updated successfully');
          fetchBatteryBoxData(currentPageBatteryBox, perPageItemBatteryBox);            
          getAllAvailableBattries();
          getAllAvailableIOT();
          setIsModalVisible(false);
          setIsLoader(false);
        } else {
          message.error('Failed to update battery box - ' + result.message);
          setIsLoader(false);
        }
      } catch (error) {
        message.error('Error updating battery box');
        setIsLoader(false);
      }
    } else if(selectedTab === 'Battery' && isEditing === false){
      try {
        const token = localStorage.getItem('token');
        const formData = batteryForm.getFieldsValue(true); 
        const formattedDate = dayjs(formData.manufacturedDateNotFormated, 'YYYY-MM-DD').format('DD/MM/YYYY');
        formData.manufacturedDate = formattedDate;
        const response = await fetch(`${BASE_URL}/battery/create-battery`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        const result = await response.json();
        if (result.statusCode === 200 || result.statusCode === 201) {
          message.success('Battery created successfully');
          fetchBatteryData(currentPageBattery, perPageItemBattery);
          setIsModalVisible(false);
          setIsLoader(false);
        } else {
          message.error('Failed to create Battery - ' + result.message);
          setIsLoader(false);
        }
      } catch (error) {
        message.error('Error creating Battery');
        setIsLoader(false);
      }
    } else if(selectedTab === 'Battery' && isEditing === true){
      try {
        const token = localStorage.getItem('token');
        const battryIdEdit = currentRecord._id;
        const formData = batteryForm.getFieldsValue(true); 
        const formattedDate = dayjs(formData.manufacturedDateNotFormated, 'YYYY-MM-DD').format('DD/MM/YYYY');
        formData.manufacturedDate = formattedDate;
        const response = await fetch(`${BASE_URL}/battery/update-battery/${battryIdEdit}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        const result = await response.json();
        if (result.statusCode === 200 || result.statusCode === 201) {
          message.success('Battery updated successfully');
          fetchBatteryData(currentPageBattery, perPageItemBattery);
          setIsModalVisible(false);
          setIsLoader(false);
        } else {
          message.error('Failed to update battery - ' + result.message);
          setIsLoader(false);
        }
      } catch (error) {
        message.error('Error updating battery');
        setIsLoader(false);
      }
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setIsQrCodeModalVisible(false);
  };

  const handleExportExcel = async () => {
    let downLoadFileName = '';
    let downLoadApiUrl = '';
    if (selectedTab === 'BatteryBox') {
      downLoadFileName = 'BatteryBox.csv';
      downLoadApiUrl = 'battery-box/export-all-battery-boxes';
    } else if (selectedTab === 'Battery') {
      downLoadFileName = 'Battery.csv';
      downLoadApiUrl = 'battery/export-batteries';
    } else if (selectedTab === 'IOT') {
      downLoadFileName = 'IOT.csv';
      downLoadApiUrl = 'iot/export-local-iot-vehicles';
    }
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/${downLoadApiUrl}`,{
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      const result = await response.json();
      if(result.statusCode === 200){
        //handleDownload(result.csvLink, downLoadFileName);
        let newObjectWithLabels = [];
        newObjectWithLabels.push(result.csvLabels);
        result.csvData.forEach(element => {
            newObjectWithLabels.push(element);
        });
        const csvData = new Blob([convertToCSV(newObjectWithLabels)], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = downLoadFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  const handleDownload = (csvLink, fileName) => {
    if (csvLink) {
      const link = document.createElement('a');
      link.href = csvLink;
      link.setAttribute('download', fileName); // Set the desired filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const handleOpenQrCode = async (event, record) => {
    event.preventDefault(); 
    console.log(record);
    try {
      setIsLoader(true);
      const token = localStorage.getItem('token');
      const battryBoxId = record._id;
      const response = await fetch(`${BASE_URL}/battery-box/get-battery-box/${battryBoxId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200 || result.statusCode === 201) {
        setBatteryBoxDetails(result.data);
        setIsQrCodeModalVisible(true);
        setIsLoader(false);
      } else {
        message.error('Failed to get battery box details - ' + result.message);
        setIsLoader(false);
      }
    } catch (error) {
      message.error('Error to get battery box details');
      setIsLoader(false);
    }
  }

  const handlePrint = useReactToPrint({
    bodyClass:"centered-content",
    documentTitle: "Battery Box QR Code",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  const handleSyncClick = async () => {
    try {      
      setIsLoader(true);
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/iot/fetch-iot-vehicles`,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        message.success('Sync process successfully');
        fetchIOTData(currentPageIOT, perPageItemIOT);
        setIsLoader(false);
      } else {
        console.error('Failed to fetch data:', result.message);
        setIsLoader(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoader(false);
    }
  }

  const ButtonsBatteryBox = [
    { text: 'Export', onClick: handleExportExcel },
    { text: 'Add', onClick: handleAddClick },
    { text: 'Delete', onClick: handleDelete },
  ];

  const ButtonsBattery = [
    { text: 'Export', onClick: handleExportExcel },
    { text: 'Add', onClick: handleAddClick },
    { text: 'Delete', onClick: handleDelete },
  ];

  const ButtonsIOT = [
    { text: 'Export', onClick: handleExportExcel },
    { text: 'Sync', onClick: handleSyncClick },
    { text: 'Delete', onClick: handleDelete },
  ];

  const columnsBatteryBox = [    
    {
      title: 'Battery Box No.',
      dataIndex: 'batteryBoxSerialNumber',
      width: '150px',
      sorter: (a, b) => a.batteryBoxSerialNumber.localeCompare(b.batteryBoxSerialNumber),

    },
    {
      title: 'Partner',
      dataIndex: 'partner',
      width: '200px',
      sorter: (a, b) => a.partner.localeCompare(b.partner),
      render: (_, record) => (
        <>
          {record.partner ? (
            record.partner.companyName
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Manufactured',
      dataIndex: 'manufacturedDate',
      width: '200px',
      sorter: (a, b) => a.manufacturedDate.localeCompare(b.manufacturedDate),
      render: (_, record) => (
        <>
          {record.battery.manufacturedDate ? (
            dayjs(record.battery.manufacturedDate).format('DD/MM/YYYY')
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Specifications',
      dataIndex: 'specifications',
      width: '200px',
      sorter: (a, b) => a.specifications.localeCompare(b.specifications),
      render: (_, record) => (
        <>
          {record.battery.specifications ? (
            record.battery.specifications
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Daily Distance',
      dataIndex: 'daily_distance',
      width: '150px',
      sorter: (a, b) => a.daily_distance.localeCompare(b.daily_distance),
      render: (_, record) => (
        <>
          {record?.iotDevice?.daily_distance}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '150px',
      sorter: (a, b) => a.status.localeCompare(b.status),      
      render(text, record) {
        return {
          props: {
            style: { 
              'background': record.status ? allstatusColors[record.status] : "",
              'color' : "#fff",
              'font-weight' :  "500"
            }
          },
          children: record.status ? allstatusText[record.status] : '-'
        };
      }
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      fixed: 'right',
      width: 130,
      render: (_, record) => (
        <span>
          <a href="#" onClick={(event) => handleOpenQrCode(event, record)}>
            <AiOutlineAppstore style={{ cursor: 'pointer', marginRight: 10 }} />
          </a>
          <a href="#">
            <FaEdit onClick={(event) => handleEdit(event, record)} style={{ cursor: 'pointer', marginRight: 16 }} />
          </a>
          <a href="#">
            <FaTrash onClick={(event) => handleDelete(event, record)} style={{ cursor: 'pointer' }} />
          </a>
        </span>
      ),
    },
  ];

  const columnsBattery = [ 
    {
      title: 'Battery Serial No',
      dataIndex: 'serialNumber',
      width: '150px',
      sorter: (a, b) => a.serialNumber.localeCompare(b.serialNumber),
    },
    {
      title: 'Manufactured Date',
      dataIndex: 'manufacturedDate',
      width: '150px',
      sorter: (a, b) => a.manufacturedDate.localeCompare(b.manufacturedDate),
      render: (_, record) => (
        <>
          {record.manufacturedDate ? (
            dayjs(record.manufacturedDate).format('DD/MM/YYYY')
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Specifications',
      dataIndex: 'specifications',
      width: '300px',
      sorter: (a, b) => a.specifications.localeCompare(b.specifications),
    },
    {
      title: 'Actions',
      width: '100px',
      dataIndex: 'action',
      render: (_, record) => (
        <span>
          <a href="#" onClick={(event) => handleEdit(event, record)}>
            <FaEdit style={{ cursor: 'pointer', marginRight: 16 }} />
          </a>
          <a href="#" onClick={(event) => handleDelete(event, record)}>
            <FaTrash style={{ cursor: 'pointer' }} />
          </a>
        </span>
      ),
    },
  ];

  const columnsIOT = [
      { 
        title: 'IOT Serial No', 
        dataIndex: 'deviceId', 
        width: '150px',
        sorter: (a, b) => a.deviceId.localeCompare(b.deviceId), 
      },
      { 
        title: 'IMEI', 
        dataIndex: 'deviceImei', 
        width: '200px',
        sorter: (a, b) => a.deviceImei.localeCompare(b.deviceImei) 
      },
      { 
        title: 'IOT Device Name', 
        dataIndex: 'name', 
        width: '250px',
        sorter: (a, b) => a.name.localeCompare(b.name), 
      },
      {
        title: 'Actions',
        dataIndex: 'action',
        width: '60px',
        render: (_, record) => (
          <span>
            <a href="#" onClick={(event) => handleDelete(event, record)}>
              <FaTrash style={{ cursor: 'pointer' }} />
            </a>
          </span>
        ),
      },
  ];

  const buttonStyle = {
      outline: 'none',
      border: 'none',
      margin: '0.75rem',
      width:"150px",
      height:"50px",
      color:colors.primary
  };

  const activeStyle = {
      backgroundColor: '#CDCDCD', 
      color: colors.primary, 
      width:"150px",
      height:"50px"
  };

  useEffect(() => {
    handelOnClickTab(selectedTab);
    getAllManufacturers();    
    getAllAvailableBattries();
    getAllAvailableIOT();
  }, []);

  return (
    <> 
      {isLoader ? (
        <PreLoader/>
      ):''}
      {selectedTab == 'BatteryBox'?  (
        <SubNavbar
          pageName="Manage Inventory"
          buttons={ButtonsBatteryBox}
        />
      ) : ('') }
      {selectedTab == 'Battery'?  (
        <SubNavbar
          pageName="Manage Inventory"
          buttons={ButtonsBattery}
        />
      ) : ('') }
      {selectedTab == 'IOT'?  (
        <SubNavbar
          pageName="Manage Inventory"
          buttons={ButtonsIOT}
        />
      ) : ('') }
      <div style={{ backgroundColor: "#F1F3F4" }}>
        <div>
          <div className="row">
            <div className="d-flex">
              <button
                  style={{
                      ...buttonStyle,
                      ...(selectedTab === 'BatteryBox' ? activeStyle : {}),
                  }}
                  onClick={() => handelOnClickTab('BatteryBox')}
              >
                  Battery Box
              </button>
              <button
                  style={{
                      ...buttonStyle,
                      ...(selectedTab === 'Battery' ? activeStyle : {}),
                  }}
                  onClick={() => handelOnClickTab('Battery')}
              >
                  Battery
              </button>
              <button
                  style={{
                      ...buttonStyle,
                      ...(selectedTab === 'IOT' ? activeStyle : {}),
                  }}
                  onClick={() => handelOnClickTab('IOT')}
              >
                  IOT
              </button>
            </div>
            <div className="col-sm-12">
              <div className="table-responsive mt-2">  
                <>  
                  {selectedTab == 'BatteryBox'?  (
                  <Table
                    rowSelection={{
                      selectedRowKeys,
                      onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys),
                    }}
                    rowKey={(record) => record._id}
                    columns={columnsBatteryBox}
                    dataSource={dataSourceBatteryBox}
                    onChange={onChange}
                    scroll={{y: `calc(100vh - 280px)`}}
                    pagination={{
                      simple: false,
                      size:"small",
                      total: totalItemsBatteryBox,
                      showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                      showQuickJumper: false,
                      showLessItems: true,
                      responsive:true,
                      defaultPageSize:perPageItemBatteryBox,
                      pageSizeOptions:[5,10,20,50,100],
                      position: ['topRight'],
                      onChange: (page, pageSize) => {
                        fetchDataOnPagination(page, pageSize);
                      }
                    }}
                    bordered
                  />
                  ):('')
                  } 
                  {selectedTab == 'Battery'?  (
                  <Table
                    rowSelection={{
                      selectedRowKeys,
                      onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys),
                    }}
                    rowKey={(record) => record._id}
                    columns={columnsBattery}
                    dataSource={dataSourceBattery}
                    onChange={onChange}
                    scroll={{y: `calc(100vh - 280px)`}}
                    pagination={{
                      simple: false,
                      size:"small",
                      total: totalItemsBattery,
                      showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                      showQuickJumper: false,
                      showLessItems: true,
                      responsive:true,
                      defaultPageSize:perPageItemBattery,
                      pageSizeOptions:[5,10,20,50,100],
                      position: ['topRight'],
                      onChange: (page, pageSize) => {
                        fetchDataOnPagination(page, pageSize);
                      }
                    }}
                    bordered
                  />
                  ):('')
                  }
                  {selectedTab == 'IOT'?  (
                  <Table
                    rowSelection={{
                      selectedRowKeys,
                      onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys),
                    }}
                    rowKey={(record) => record._id}
                    columns={columnsIOT}
                    dataSource={dataSourceIOT}
                    onChange={onChange}
                    scroll={{y: `calc(100vh - 280px)`}}
                    pagination={{
                      simple: false,
                      size:"small",
                      total: totalItemsIOT,
                      showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                      showQuickJumper: false,
                      showLessItems: true,
                      responsive:true,
                      defaultPageSize:perPageItemIOT,
                      pageSizeOptions:[5,10,20,50,100],
                      position: ['topRight'],
                      onChange: (page, pageSize) => {
                        fetchDataOnPagination(page, pageSize);
                      }
                    }}
                    bordered
                  />
                  ):('')
                  }
                </>  
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={modalTitleOfAction}
        visible={isModalVisible}
        centered
        onCancel={handleModalCancel}
        footer={[
          <CustomButton key="submit" onClick={handleModalOk} text={isEditing ? "Save Changes" : "Add"} />
        ]}
      >
        {selectedTab === 'BatteryBox' ? (
          <>          
            <Form form={batteryBoxForm}>
              <div className="row my-3">
                <div className="col-12">
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="iotDevice" className="form-label">
                        IOT Device IMEI
                      </label>
                    </div>
                    <div className="col-8">
                      <Form.Item name="iotDevice" label=""> 
                        <Select
                          showSearch
                          placeholder="Select a Device IMEI"
                          optionFilterProp="label"  // Searches within the value prop
                          style={{ width: '100%' }}
                          onChange={(value) => console.log(`Selected: ${value}`)}
                          filterOption={(input, option) =>
                            option?.children.toLowerCase().includes(input.toLowerCase())
                          }
                        >  
                          <Option value="">--Select IOT Device IMEI--</Option>
                          {allAvailableIOT.map(availiot => (
                              <Option key={availiot._id} value={availiot._id}>{availiot.deviceImei}</Option>
                          ))}  
                          {currentRecord !== null ? 
                            <Option key={currentRecord.iotDevice._id} value={currentRecord.iotDevice._id}>{currentRecord.iotDevice.deviceImei}</Option>
                          : ''}          
                        </Select>
                      </Form.Item> 
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="battery" className="form-label">
                        Battery Serial No.
                      </label>
                    </div>
                    <div className="col-8">
                      <Form.Item name="battery" label="">          
                        <Select
                          showSearch
                          placeholder="Select a Battery"
                          optionFilterProp="label"  // Searches within the value prop
                          style={{ width: '100%' }}
                          onChange={(value) => console.log(`Selected: ${value}`)}
                          filterOption={(input, option) =>
                            option?.children.toLowerCase().includes(input.toLowerCase())
                          }
                        >
                          <Option value="">--Select Battery Serial No--</Option>
                          {allAvailableBattries.map(availbattery => (
                              <Option key={availbattery._id} value={availbattery._id}>{availbattery.serialNumber}</Option>
                          ))}    
                          {currentRecord !== null ? 
                            <Option key={currentRecord.battery._id} value={currentRecord.battery._id}>{currentRecord.battery.serialNumber}</Option>
                          : ''}           
                        </Select>
                      </Form.Item> 
                    </div>
                  </div>
                </div>
              </div>
            </Form>          
          </>
        ) : ''}
        {selectedTab === 'Battery' ? (
          <>
            <Form form={batteryForm}>
              <div className="row my-3">
                <div className="col-12">
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="serialNumber" className="form-label">
                        Battery Serial No.
                      </label>
                    </div>
                    <div className="col-8">
                      <Form.Item name="serialNumber" label="">
                        <Input
                          placeholder="Battery Serial Number"
                          className="form-control"
                        />
                      </Form.Item> 
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="manufacturedDateNotFormated" className="form-label">
                        Manufacturing Date
                      </label>
                    </div>
                    <div className="col-8">
                      <Form.Item name="manufacturedDateNotFormated" label="">
                        <DatePicker
                          className="form-control"
                          format="DD/MM/YYYY"
                          maxDate={dayjs(new Date())}
                        />
                      </Form.Item> 
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="specifications" className="form-label">
                        Specifications
                      </label>
                    </div>
                    <div className="col-8">
                      <Form.Item name="specifications" label="">
                        <Input
                          placeholder="Specifications"
                          className="form-control"
                        />
                      </Form.Item> 
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="manufacturer" className="form-label">
                        Manufacturer Name
                      </label>
                    </div>
                    <div className="col-8">
                      <Form.Item name="manufacturer" label="">    
                        <Select
                          showSearch
                          placeholder="Select a Manufacturer"
                          optionFilterProp="label"  // Searches within the value prop
                          style={{ width: '100%' }}
                          onChange={(value) => console.log(`Selected: ${value}`)}
                          filterOption={(input, option) =>
                            option?.children.toLowerCase().includes(input.toLowerCase())
                          }
                        >  
                          <Option value="">--Select Manufacturer--</Option>
                          {allManufacturers.map(manufacturer => (
                              <Option key={manufacturer._id} value={manufacturer._id}>{manufacturer.companyName}</Option>
                          ))}            
                        </Select>
                      </Form.Item> 
                    </div>
                  </div>
                </div>
              </div>
            </Form>          
          </>
        ) : ''}
      </Modal>  
      <Modal
        title=''
        visible={isQrCodeModalVisible}
        onCancel={handleModalCancel}
        footer={null}
        centered
        width={200}
      >
        <div className='row'>
          <div className='col-md-12 text-center'>
            <div className='col-12' ref={contentToPrint}>
              <h6 className='mt-2'>{batteryBoxDetails.batteryBoxSerialNumber}</h6>
              <img src={batteryBoxDetails.qrURL} className='img-fluid'/>
            </div>
            <CustomButton key="download" onClick={() => { handlePrint(null, () => contentToPrint.current); }}  text='Print'/>
          </div>
        </div>
      </Modal>  
    </>
  );
};

export default InventoryDataTable;
