import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import colors from '../../components/Constant/Color';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { Form, Input, Checkbox, Select, message } from 'antd';
import CustomButton from '../Button/CustomButton';
import { BASE_URL } from '../Constant/config';
import PreLoader from '../PreLoader/PreLoader';
import Swal from 'sweetalert2';

const GlobalSetting = () => {
  const [globalSettingsForm] = Form.useForm();
  const navigate = useNavigate();
  const { Option } = Select;
  const [allLocations, setAllLocations] = useState([]);
  const [allLocationsIds, setAllLocationsIds] = useState([]);
  const [selectedLocationsIds, setSelectedLocationsIds] = useState([]);
  const [settingCounter, setSettingCounter] = useState(1);
  const [settingsOptions, setsettingsOptions] = useState([0]);
  const [currentSettings, setCurrentSettings] = useState([]);

  useEffect(() => {
    getAllSettings();
    getAllLocations();
  }, []);

  const getAllLocations = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/location/get-locations?limit=50000&page=1`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && Array.isArray(result.data.locations)) {
        const locationsList = result.data.locations.map(location => ({
          _id: location._id,
          title: location.locality ? location.locality:"-"+"|"+location.city ? location.city:"-"+"|"+location.state ? location.state:"-"+"|"+location.pincode ? location.pincode:"-",
        }));
        setAllLocationsIds(result.data.locations.map(p => p._id),);
        setAllLocations(locationsList);
        //console.log(result.data.locations);
      } else {
        console.error('Unexpected data format:', result);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const getAllSettings = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/settings/get-global-settings`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if(result.data.length > 0){
        setCurrentSettings(result.data);
        let allSettingsList = [];
        let currentPos = 0;
        result.data.forEach((settings, pos) => {
          allSettingsList = [...allSettingsList, currentPos];
          currentPos = currentPos + 1;
        });
        setsettingsOptions(allSettingsList);   
        setSettingCounter(currentPos);        
        globalSettingsForm.setFieldsValue({settings:result.data});
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  const handleAddOptions = (event) => {
    event.preventDefault();
    globalSettingsForm.validateFields().then(async (values) => {
      let allSettings = globalSettingsForm.getFieldsValue(true);
      let allSettingsLocations = [];
      let isLocationError = false;
      settingsOptions.forEach((settingPos, index) => {
        if(allSettings['settings'][settingPos]['locations']){
          const checkExist = allSettingsLocations.filter(value => allSettings['settings'][settingPos]['locations'].includes(value));
          if(checkExist.length === 0){
            allSettingsLocations = [...allSettingsLocations, ...allSettings['settings'][settingPos]['locations']]; 
          } else {
            isLocationError = true;
          }
        }
      });
      if(!isLocationError){
        const checkAllFull = allLocationsIds.filter(value => allSettingsLocations.includes(value));
        console.log('allLocationsIds', allLocationsIds);
        console.log('allSettingsLocations', allSettingsLocations);
        console.log('checkAllFull', checkAllFull);
        if(checkAllFull.length !== allLocationsIds.length){
          setSettingCounter(settingCounter + 1);
          setsettingsOptions([...settingsOptions, settingCounter]);   
          setSelectedLocationsIds([]);
        } else {
          message.error('You have already used all locations for settings!');
        }
      } else {
        message.error('You have selected same location in multiple settings! please select unique locations.');
      }
    })
    .catch((errorInfo) => {
      console.log(errorInfo);
    });
  };

  const handleRemoveOptions = (event, position) => {
    event.preventDefault();
    const values = [...settingsOptions];
    values.splice(position, 1);
    setsettingsOptions(values);
  };

  const handleSubmitSettings = (event) => {
    event.preventDefault();
    globalSettingsForm.validateFields().then(async (values) => {   
      try {   
        const settingsData = globalSettingsForm.getFieldsValue(true);
        let submitSettings = [];
        let allSettingsLocations = [];
        let isLocationError = false;
        settingsOptions.forEach((settingPos, index) => {
          if(settingsData['settings'][settingPos]['locations'] && settingsData['settings'][settingPos]['locations'].length > 0){
            const checkExist = allSettingsLocations.filter(value => settingsData['settings'][settingPos]['locations'].includes(value));
            if(checkExist.length === 0){
              allSettingsLocations = [...allSettingsLocations, ...settingsData['settings'][settingPos]['locations']]; 
            } else {
              isLocationError = true;
            }
            settingsData['settings'][settingPos]['applyToAll'] = false;
          } else {
            settingsData['settings'][settingPos]['applyToAll'] = true;
          }
        });
        settingsData['settings'].forEach((settingData, index) => {
          if(settingData === undefined || settingsOptions.includes(index) === false){
            settingsData['settings'].splice(index, 1);
          }
        });        
        if(!isLocationError){
          const token = localStorage.getItem('token');
          const response = await fetch(`${BASE_URL}/settings/create-global-settings`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(settingsData),
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success(result.message);
            setsettingsOptions([1]);
            setSettingCounter(2);
            getAllSettings();
            globalSettingsForm.resetFields();
            settingsData['settings'].forEach((settingData, index) => {
              if(settingData.locations && settingData.locations.length > 0){
                handleUpdateSettingsOnLocation(settingData);
              }
            });        
          } else {
            message.error(result.message);
          }
        } else {
          message.error('You have selected same location in multiple settings! please select unique locations.');
        }
      } catch (error) {
        message.error('Error creating settings');
      }
    })
    .catch((errorInfo) => {
      console.log(errorInfo);
    });
  }

  const handleUpdateSettingsOnLocation = async (submitLocatioSettingData) => {
    try {   
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/settings/update-global-settings`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submitLocatioSettingData),
      });
    } catch (error) {
      console.error('Error creating settings on location');
    }
  }

  const handleTabChange = (key, event) => {
    console.log(key);
    console.log(event);
    if (key === "promotionalContent") {
      navigate(`/settings/promotional-content`);
    } else if(key === "notifications"){
      navigate(`/settings/notifications`);
    } else if(key === "userRoles"){
      navigate(`/settings/user-roles`);
    } else if(key === "offers"){
      navigate(`/settings/offers`);
    } else if(key === "issues"){
      navigate(`/settings/issues`);
    } else if(key === "global"){
      navigate(`/settings/global-settings`);
    }
  }

  return (
    <>
      <div className='col-md-12'>
        <h6 className="my-4">Manage Settings</h6>
        <Tab.Container defaultActiveKey="global" onSelect={handleTabChange}>
          <div className='row'>
            <div className='col-md-12'>
              <Nav variant="pills" className="flex-row tabbar-top" style={{backgroundColor: colors.primary}}>
                <Nav.Item>
                  <Nav.Link eventKey="global">Global</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="issues">Issues</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="offers">Offers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="userRoles" to="/user-roles">User Roles</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="notifications">Notifications</Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link eventKey="promotionalContent">Promotional Content</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="custom-tab-content">
                <Tab.Pane
                eventKey="global" style={{backgroundColor:"transparent"}}>
                  <Form form={globalSettingsForm}>
                    {settingsOptions.map((settingsPos, index) => (
                      <div className="settingsBox" key={settingsPos}>
                        <div className="row mb-2">
                          <div className='col-6'>
                            <div className="row mb-2">
                              <div className="col-4">
                                <label htmlFor="perday_cost" className="form-label">Per Day Cost:</label>
                              </div>
                              <div className="col-8">
                                <Form.Item name={['settings', settingsPos, 'perday_cost']} label="" rules={[{ required: true, message: 'Please enter per day cost!' }, { pattern: /^[0-9]+$/, message: 'Input must only contain numbers' }]}>
                                  <Input
                                    placeholder="100"
                                    className="form-control"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4">
                                <label htmlFor="upfront_cost" className="form-label">Upfront Cost:</label>
                              </div>
                              <div className="col-8">
                                <Form.Item name={['settings', settingsPos, 'upfront_cost']} label="" rules={[{ required: true, message: 'Please enter upfront cost!' }, { pattern: /^[0-9]+$/, message: 'Input must only contain numbers' }]}>
                                  <Input
                                    placeholder="100"
                                    className="form-control"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4">
                                <label htmlFor="driver_referral_bonus" className="form-label">Driver Referral Bonus:</label>
                              </div>
                              <div className="col-8">
                                <Form.Item name={['settings', settingsPos, 'driver_referral_bonus']} label="" rules={[{ required: true, message: 'Please enter driver referral bonus!' }, { pattern: /^[0-9]+$/, message: 'Input must only contain numbers' }]}>
                                  <Input
                                    placeholder="100"
                                    className="form-control"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className='col-6'>
                            <div className="row mb-2">
                              <div className="col-4">
                                <label htmlFor="security_deposit" className="form-label">Security Deposit:</label>
                              </div>
                              <div className="col-8">
                                <Form.Item name={['settings', settingsPos, 'security_deposit']} label="" rules={[{ required: true, message: 'Please enter security deposit!' }, { pattern: /^[0-9]+$/, message: 'Input must only contain numbers' }]}>
                                  <Input
                                    placeholder="100"
                                    className="form-control"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4">
                                <label htmlFor="buy_back_cost" className="form-label">Buy Back Cost:</label>
                              </div>
                              <div className="col-8">
                                <Form.Item name={['settings', settingsPos, 'buy_back_cost']} label="" rules={[{ required: true, message: 'Please enter buy back cost!' }, { pattern: /^[0-9]+$/, message: 'Input must only contain numbers' }]}>
                                  <Input
                                    placeholder="100"
                                    className="form-control"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4">
                                <label htmlFor="partner_referral_bonus" className="form-label">Partner Referral Bonus:</label>
                              </div>
                              <div className="col-8">
                                <Form.Item name={['settings', settingsPos, 'partner_referral_bonus']} label="" rules={[{ required: true, message: 'Please enter partner referral bonus!' }, { pattern: /^[0-9]+$/, message: 'Input must only contain numbers' }]}>
                                  <Input
                                    placeholder="100"
                                    className="form-control"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        </div>
                        {index > 0 ? 
                        <div className="row mb-2">
                          <div className='col-6'>
                            <div className="row mb-2">
                              <div className="col-4">
                                <label htmlFor="locations" className="form-label">Locations:</label>
                              </div>
                              <div className="col-8">  
                                <Form.Item name={['settings', settingsPos, 'locations']} label="" rules={[{ required: true, message: 'Please select locations!' }]}>          
                                  <Select style={{ width: '100%' }} placeholder="Select Locations" mode="multiple" allowClear>
                                    {allLocations.map(location => (
                                        <Option key={location._id} value={location._id}>{location.title}</Option>
                                    ))}            
                                  </Select>
                                </Form.Item>                     
                              </div>
                            </div>
                          </div>
                          <div className='col-6'>
                            <div className="row mb-2">
                              <div className="col-12 text-end">
                                <CustomButton key={`button-${settingsPos}-remove`} onClick={(event) => handleRemoveOptions(event, index)} text="Remove Setting" className="w-auto"/>
                              </div>
                            </div>
                          </div>
                        </div>
                        :                         
                        <div className="row mb-2">
                          <div className='col-6'>
                            <div className="row mb-2">
                              <div className="col-12">
                                <label>Applicable to all locations</label>
                              </div>
                            </div>
                          </div>
                        </div> 
                        }
                      </div>
                    ))}
                    <div className='col-md-12'>
                      <div className='ant-modal-footer'>
                        <CustomButton key="button-add" onClick={(event) => handleAddOptions(event)} text="Add More Settings On Based Of Locations" />
                        <CustomButton key="button-save" onClick={(event) => handleSubmitSettings(event)} text="Save" />
                      </div>
                    </div>
                  </Form>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default GlobalSetting;
