import React, { useState, useEffect } from 'react';
import { Flex, Table, Modal, Input, Select, Upload, Button, Image, Form, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import SubNavbar from '../Header/SubNavbar';
import { FaRegCalendarAlt } from "react-icons/fa";
import { UploadOutlined } from '@ant-design/icons';
import CustomButton from '../Button/CustomButton';
import { BASE_URL } from '../Constant/config';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PreLoader from '../PreLoader/PreLoader';
dayjs.extend(customParseFormat);
let allstatusText = [];
allstatusText['new'] = 'New';
allstatusText['acknowledged'] = 'Acknowledged';
allstatusText['in_progress'] = 'In Progress';
allstatusText['submitted_to_partner'] = 'Submitted To Partner';
allstatusText['recovered_by_partner'] = 'Recovered By Partner';
allstatusText['eliminated'] = 'Eliminated';
let allstatusColors = [];
allstatusColors['new'] = '#8A0102';
allstatusColors['acknowledged'] = '#248B22';
allstatusColors['in_progress'] = '#02469B';
allstatusColors['submitted_to_partner'] = '#FF4500';
allstatusColors['recovered_by_partner'] = '#A9A9A9';
allstatusColors['eliminated'] = '#A9A9A9';

const RecoverBatteryDataTable = () => {
  const [recoveryForm] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [modalType, setModalType] = useState('');
  const [currentRecord, setCurrentRecord] = useState(null);
  const [allLocations, setAllLocations] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [perPageItem, setPerPageItem] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { Option } = Select;


  const [filters, setFilters] = useState({
    batteryBoxSerialNumber: "",
    driverName: "",
    partnerName: "",
    teamLeadName: '',
    Location: "",
    Status: "",
    Request: '',
    Submitted: "",
    action: ""
  });

  useEffect(() => {
    fetchData(currentPage, perPageItem);
    getAllLocations();
  }, []);

  const fetchData = async (page, pageSize) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/recovery/get-recovery-list?limit=${pageSize}&page=${page}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        setDataSource(result.data.recoveries);
        setTotalItems(result.totalCount);
      } else {
        console.error('Failed to fetch data:', result.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getAllLocations = async () => {
    try {
      setIsLoader(true);
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/location/get-locations?limit=50000&page=1`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && Array.isArray(result.data.locations)) {
        const locationsList = result.data.locations.map(location => ({
          _id: location._id,
          title: location.locality ? location.locality:"-"+"|"+location.city ? location.city:"-"+"|"+location.state ? location.state:"-"+"|"+location.pincode ? location.pincode:"-",
        }));
        setAllLocations(locationsList);
        setIsLoader(false);
        //console.log(result.data.locations);
      } else {
        console.error('Unexpected data format:', result);
        setIsLoader(false);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
      setIsLoader(false);
    }
  };

  const handleFilterChange = (dataIndex, value) => {
    setFilters((prev) => ({
      ...prev,
      [dataIndex]: value,
    }));
  };

  const columns = [
    {
      title: (
        <div>
          <div>Battery Code</div>
          {/* <Input
            placeholder="Battery Code"
            value={filters.batteryBoxSerialNumber}
            onChange={(e) => handleFilterChange('batteryBoxSerialNumber', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'batteryBoxSerialNumber',
      sorter: (a, b) => a.batteryBoxSerialNumber.localeCompare(b.batteryBoxSerialNumber),
      width: 200,
    },  
    {
      title: (
        <div>
          <div>Driver</div>
          {/* <Input
            placeholder="Driver"
            value={filters.driverName}
            onChange={(e) => handleFilterChange('driverName', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'driverName',
      sorter: (a, b) => a.driverName.localeCompare(b.driverName),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Partner</div>
          {/* <Input
            placeholder="Partner"
            value={filters.partnerName}
            onChange={(e) => handleFilterChange('partnerName', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'partnerName',
      sorter: (a, b) => a.partnerName.localeCompare(b.partnerName),
      width: 200,
    },
    {
      title: (
        <div>
          <div>TL</div>
          {/* <Input
            placeholder="TL"
            value={filters.teamLeadName}
            onChange={(e) => handleFilterChange('teamLeadName', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'teamLeadName',
      sorter: (a, b) => a.teamLeadName.localeCompare(b.teamLeadName),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Location</div>
          {/* <Input
            placeholder="Location"
            value={filters.location}
            onChange={(e) => handleFilterChange('location', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'location',
      sorter: (a, b) => a.location.localeCompare(b.location),
      width: 200,
      // render: (_, record) => (
      //   <>
      //     {record.location ? (
      //       `${record.location.locality}, ${record.location.city}, ${record.location.state}, ${record.location.pincode}`
      //     ) : (
      //       '-'
      //     )}
      //   </>
      // ),
    },
    {
      title: (
        <div>
          <div>Status</div>
          {/* <Input
            placeholder="Status"
            value={filters.status}
            onChange={(e) => handleFilterChange('status', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      width: 200,     
      render(text, record) {
        return {
          props: {
            style: { 
              'background': record.status ? allstatusColors[record.status] : "",
              'color' : "#fff",
              'font-weight' :  "500"
            }
          },
          children: record.status ? allstatusText[record.status] : '-'
        };
      }
    },
    {
      title: (
        <div>
          <div>Request Generated By system</div>
          {/* <Input
            placeholder="Request Generated By system"
            value={filters.requestedDate}
            onChange={(e) => handleFilterChange('Request', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'requestedDate',
      sorter: (a, b) => a.requestedDate.localeCompare(b.requestedDate),
      width: 200,
      // render: (_, record) => (
      //   <>
      //     {record.requestedDate ? (
      //       dayjs(record.requestedDate).format('DD/MM/YYYY')
      //     ) : (
      //       '-'
      //     )}
      //   </>
      // ),
    },
    {
      title: (
        <div>
          <div>Battery Submitted to partner</div>
          {/* <Input
            placeholder="Battery Submitted to partner"
            value={filters.submittedToPartnerDate}
            onChange={(e) => handleFilterChange('submittedToPartnerDate', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'submittedToPartnerDate',
      sorter: (a, b) => a.submittedToPartnerDate.localeCompare(b.submittedToPartnerDate),
      width: 200,
      // render: (_, record) => (
      //   <>
      //     {record.submittedToPartnerDate ? (
      //       dayjs(record.submittedToPartnerDate).format('DD/MM/YYYY')
      //     ) : (
      //       '-'
      //     )}
      //   </>
      // ),
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      fixed: 'right',
      width: 80,
      render: (_, record) => (
        <span>
          <a href='#' onClick={(event) => handleRecoveryDetail(event, record)}>
            <FaRegCalendarAlt />
          </a>
        </span>
      ),
    },
  ];

  const handleRecoveryDetail = async (event, record) => {
    event.preventDefault();   
    setCurrentRecord(record);
    recoveryForm.resetFields();
    try {
      const token = localStorage.getItem('token');
      const userId = record._id;
      const response = await fetch(`${BASE_URL}/recovery/get-recovery/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        let battryDataRecovery = result.data;
        battryDataRecovery._id = userId;
        setCurrentRecord(battryDataRecovery);
        setIsLoader(false);
        recoveryForm.setFieldsValue({ 
          location: result.data?.location?._id,
          status: result.data?.status,
        });
      } else {
        console.error('Failed to fetch data:', result.message);
        setIsLoader(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoader(false);
    }
    setModalType('edit');
    setIsModalVisible(true);
  };

  const handleDelete = (key) => {
    console.log('Delete clicked for key:', key);
  };

  const handleAddClick = () => {
    setModalType('add');
    setCurrentRecord(null);
    setIsModalVisible(true);
  };

  const handleOk = async () => {   
    try {
      setIsLoader(true);
      const token = localStorage.getItem('token');
      const createFormData = recoveryForm.getFieldsValue(true);  
      const FormData = require('form-data');
      const formData = new FormData();
      for (const key in createFormData) {
        formData.append(key, createFormData[key]);
      } 
      createFormData['assetURL'] ? createFormData['assetURL'].fileList ? formData.append('assetURL', createFormData['assetURL'].fileList[0].originFileObj) : formData.delete("assetURL") : console.log('assetURL');
      const recoveryId = currentRecord._id; 
      const response = await fetch(`${BASE_URL}/recovery/update-recovery/${recoveryId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });
      const result = await response.json();
      if (result.statusCode === 200 || result.statusCode === 201) {
        message.success('Recovery updated successfully');
        fetchData(currentPage, perPageItem);
        setIsModalVisible(false);
        recoveryForm.resetFields();
        setIsLoader(false);
      } else {
        message.error('Failed to update recovery - ' + result.message);
        setIsLoader(false);
      }
    } catch (error) {
      message.error('Error updateing recovery');
      setIsLoader(false);
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setCurrentPage(pagination.current);
    setPerPageItem(pagination.pageSize);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const uploadProps = {
    beforeUpload: (file) => {
      return false;
    },
    accept: 'image/*',
    maxCount: 1
  };
  
  return (
    <>
      {isLoader ? (
        <PreLoader/>
      ):''}
      <SubNavbar
        pageName="Recover Battery"
        buttons={[
          { text: 'History' },
          { text: 'Export', onClick: handleDelete }
        ]}
      />
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive mt-2">
            <Table
              rowSelection={rowSelection}
              rowKey={(record) => record._id}
              columns={columns}
              dataSource={dataSource}
              onChange={onChange}
              scroll={{y: `calc(100vh - 280px)`}}
              pagination={{
                simple: false,
                size:"small",
                total: totalItems,
                showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                showQuickJumper: false,
                showLessItems: true,
                responsive:true,
                defaultPageSize:perPageItem,
                pageSizeOptions:[5,10,20,50,100],
                position: ['topRight'],
                onChange: (page, pageSize) => {
                  console.log(page, pageSize);
                  fetchData(page, pageSize);
                }
              }}
              bordered
            />
          </div>
        </div>
      </div>
      <Modal
        title='Recover Battery'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <CustomButton key="submit" onClick={handleOk} text='Update'/>
        ]}
        width={950}
      >
        {currentRecord ? (
          <>            
            <Form form={recoveryForm}>
              <div className="row">
                <div className="col-6">
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="batteryBoxSerialNumber" className="form-label">Battery Code</label>
                    </div>
                    <div className="col-8">
                      <h6>{currentRecord.batteryBoxSerialNumber ? currentRecord.batteryBoxSerialNumber : '-'}</h6>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="driverName" className="form-label">Driver Name</label>
                    </div>
                    <div className="col-8">
                      <h6>{currentRecord.driverName ? currentRecord.driverName : '-'}</h6>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="partnerName" className="form-label">Partner Name</label>
                    </div>
                    <div className="col-8">
                      <h6>{currentRecord.partnerName ? currentRecord.partnerName : '-'}</h6>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="teamLeadName" className="form-label">Team Lead</label>
                    </div>
                    <div className="col-8">
                      <h6>{currentRecord.teamLeadName ? currentRecord.teamLeadName : '-'}</h6>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="Address" className="form-label">Upload Screen Shot</label>
                    </div>
                    <div className="col-8 images-block-upload">
                      <Form.Item name="assetURL" label="">
                        <Upload {...uploadProps}>
                          <Button icon={<UploadOutlined />}>Select File</Button>
                        </Upload>
                      </Form.Item> 
                      {currentRecord.assetURL ?                  
                      <Image
                        width={30}
                        height={30}
                        src={currentRecord.assetURL}
                      /> : ''}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="Batteries" className="form-label">Location</label>
                    </div>
                    <div className="col-8"> 
                        <Form.Item name="location" label="">                    
                          <Select style={{ width: '100%' }}>
                            {allLocations.map(location => (
                                <Option key={location._id} value={location._id}>{location.title}</Option>
                            ))}            
                          </Select>
                        </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="Aadhar" className="form-label">Status</label>
                    </div>
                    <div className="col-8">
                        <Form.Item name="status" label="">                    
                          <Select style={{ width: '100%' }}>
                            {Object.entries(allstatusText).map(([key, value]) => (
                                <Option key={key} value={key}>{value}</Option>
                            ))}            
                          </Select>
                        </Form.Item>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="requestedDate" className="form-label">Request Generated By System</label>
                    </div>
                    <div className="col-8">
                      <h6>
                        {currentRecord.requestedDate ? dayjs(currentRecord.requestedDate).format('DD-MM-YYYY hh:mma') : '-'}
                      </h6>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="acknowledgedDate" className="form-label">Request Acknowledged by TL</label>
                    </div>
                    <div className="col-8">
                      <h6>
                        {currentRecord.acknowledgedDate ? dayjs(currentRecord.acknowledgedDate).format('DD-MM-YYYY hh:mma') : '-'}
                      </h6>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="processingStartDate" className="form-label">Processing Started by TL</label>
                    </div>
                    <div className="col-8">
                      <h6>
                        {currentRecord.processingStartDate ? dayjs(currentRecord.processingStartDate).format('DD-MM-YYYY hh:mma') : '-'}
                      </h6>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="submittedToPartnerDate" className="form-label">Battery Submitted to Partner</label>
                    </div>
                    <div className="col-8">
                      <h6>
                        {currentRecord.submittedToPartnerDate ? dayjs(currentRecord.submittedToPartnerDate).format('DD-MM-YYYY hh:mma') : '-'}
                      </h6>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="Company PAN" className="form-label">Remarks</label>
                    </div>
                    <div className="col-8">
                        <Form.Item name="remark" label=""> 
                          <TextArea placeholder='Remarks' />
                        </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </>
        ) : ''}
      </Modal>
    </>
  );
};

export default RecoverBatteryDataTable;
